export enum TextType {
  T48 = "T-48",
  T40 = "T-40",
  T32 = "T-32",
  T24 = "T-24",
  T18 = "T-18",
  H32 = "H-32",
  H24 = "H-24",
  H20 = "H-20",
  H18 = "H-18",
  H16 = "H-16",
  H14 = "H-14",
  H12M = "H-12-M",
  H12 = "H-12",
  H10 = "H-10",
  S20 = "S-20",
  S18 = "S-18",
  S16 = "S-16",
  S14 = "S-14",
  P18 = "P-18",
  U16 = "U-16",
  U16R = "U-16-R",
  U14 = "U-14",
  U14R = "U-14-R",
  P16 = "P-16",
  P14 = "P-14",
  P12 = "P-12",
  CM14 = "CM-14",
  CD12 = "CD-200",
}

export default TextType;
