import { TagType } from "@components/InsightTag/constants/types";
import { ColorType } from "@styles/theme";

export const insightTagColors: Record<TagType, ColorType> = {
  [TagType.red]: "R75",
  [TagType.amber]: "Y75",
  [TagType.green]: "G200",
  [TagType.jubilee_facts]: "INDIGO500",
};

export const insightTextColors: Record<TagType, ColorType> = {
  [TagType.red]: "ERROR800",
  [TagType.amber]: "CAUTION800",
  [TagType.green]: "G500",
  [TagType.jubilee_facts]: "INDIGO900",
};
