import { ColorType, theme } from "@styles/theme";
import styled from "styled-components";
import { TagSize } from "./types";

interface StyledTagProps {
  backgroundColor: ColorType;
  color: ColorType;
  containIcon: boolean;
  containText: boolean;
}

export const StyledTag = styled.div<StyledTagProps>`
  width: fit-content;
  height: 32px;
  border-radius: 24px;
  padding: 8px;
  padding-right: ${({ containText }) => (containText ? "16px" : "8px")};
  padding-left: ${({ containIcon }) => (containIcon ? "8px" : "16px")};
  background-color: ${({ backgroundColor }) => theme.colors[backgroundColor]};
  color: ${({ color }) => theme.colors[color]};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  letter-spacing: 1px;
`;

interface StyledIconContainerProps {
  addMargin: boolean;
  size: TagSize;
}

export const StyledIconContainer = styled.div<StyledIconContainerProps>`
  margin: ${({ addMargin }) => (addMargin ? "10px 8px 5px 5px" : "0")};

  svg {
    width: ${({ size }) => (size === "small" ? "16px" : "24px")};
    height: ${({ size }) => (size === "small" ? "16px" : "24px")};
  }
`;
