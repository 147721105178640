import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import styled from "styled-components";

export const CardExpander = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px;
  min-width: calc(33.3% - 16px);
  overflow: hidden;

  @media ${MEDIA_QUERIES.PHONE} {
    min-width: 100%;
    min-height: 70px;
  }
`;

export const Spacing = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
