import { Text } from "@ifgengineering/component-library";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  border-radius: 9999px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  & > p {
    padding: 0 1.5em;
    text-overflow: ellipsis;
  }
  @media (max-width: 846px) {
    & > p {
      text-overflow: ellipsis;
      padding: 0 0.8em;
      font-size: 12px;
    }
  }
  @media (max-width: 345px) {
    & > p {
      text-overflow: ellipsis;
      padding: 0 0.75em;
      font-size: 8.95555px;
    }
  }
`;

const LogoContainer = styled.div`
  width: 4.5em;
  height: 4.5em;
  overflow: hidden;
  border-radius: 50%;
  border: solid 2px white;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 846px) {
    width: 2.45em;
    height: 2.45em;
  }
  @media (max-width: 345px) {
    width: 2em;
    height: 2em;
  }
`;

export interface PartnerCharityChipProps {
  logoUrl: string;
  name: string;
}

export const PartnerCharityChip: React.FC<PartnerCharityChipProps> = ({
  logoUrl,
  name,
}) => {
  return (
    <Container>
      <LogoContainer>
        <img src={logoUrl} alt={name + " logo"} />
      </LogoContainer>
      <Text color="WHITE" type="P16">
        {name}
      </Text>
    </Container>
  );
};

export default PartnerCharityChip;
