import { Text } from "@ifgengineering/component-library";
import React, { FC } from "react";
import { calcPercentage } from "./helper";
import {
  AssetsBar,
  BarContainer,
  PortfolioAllocationBarContainer,
  TempLiabilityValue,
  Title,
  ValuesContainer,
  ValuesItem,
} from "./styled";

interface PortfolioAllocationBarProps {
  assetsValue: number;
  liabilitiesValue: number;
  formatCurrency: (x: number) => string;
}

export const PortfolioAllocationBar: FC<PortfolioAllocationBarProps> = ({
  assetsValue,
  liabilitiesValue,
  formatCurrency,
}) => {
  const total = assetsValue + liabilitiesValue;
  const assetsPercentage = calcPercentage(assetsValue, total);
  const liabilitiesPercentage = calcPercentage(liabilitiesValue, total);

  return (
    <PortfolioAllocationBarContainer>
      <ValuesContainer>
        <ValuesItem>
          <Title>
            <Text color="BLUE999" type="P16">
              Assets
            </Text>
          </Title>
          <Text color="G400" type="T24">
            {formatCurrency(assetsValue)}
          </Text>
          <Text color="SLATE500" type="S16">
            {assetsPercentage}%
          </Text>
        </ValuesItem>
        <ValuesItem>
          <Title>
            <Text color="BLUE999" type="P16">
              Liabilities
            </Text>
          </Title>
          <TempLiabilityValue>
            {/* Don't show the minus sing if Liability is Zero */}
            {liabilitiesValue > 0 && "-"}
            {formatCurrency(liabilitiesValue)}
          </TempLiabilityValue>
          <Text color="SLATE500" type="S16">
            {liabilitiesPercentage}%
          </Text>
        </ValuesItem>
      </ValuesContainer>
      <BarContainer>
        <AssetsBar percentage={assetsPercentage}></AssetsBar>
      </BarContainer>
    </PortfolioAllocationBarContainer>
  );
};
