import React, { FC } from "react";
import Icon from "@icon-park/react/es/all";
import { Text, Card, theme } from "@ifgengineering/component-library";
import { AssetBreakdownContainer, EditAssets } from "./AssetBreakdown.styled";
import { AssetCard } from "./AssetCard";
import { CardExpander } from "./AssetCard.styled";

interface AssetBreakdownItem {
  label: string;
  value: number;
}

interface AssetBreakdownProps {
  assets: AssetBreakdownItem[];
  enableEditAssets?: boolean;
  onEdit?: () => void;
  onClickInvest?: () => void;
  formatCurrency: (x: number) => string;
}

export const AssetBreakdown: FC<AssetBreakdownProps> = ({
  assets,
  enableEditAssets,
  onEdit,
  onClickInvest,
  formatCurrency,
}) => {
  return (
    <AssetBreakdownContainer>
      {assets.map(({ label, value }) => (
        <AssetCard
          formatCurrency={formatCurrency}
          label={label}
          value={value}
          key={`${label}-$value}`}
        />
      ))}
      {enableEditAssets && (
        <CardExpander>
          <Card background="B50">
            <EditAssets onClick={onEdit}>
              <Icon
                type="Edit"
                size={18}
                theme="outline"
                fill={theme.colors.BLUE700}
              />
              <Text color="BLUE700" type="S18" as="div">
                Add/Update Assets
              </Text>
            </EditAssets>
          </Card>
        </CardExpander>
      )}
      <CardExpander>
        <Card background="B50">
          <EditAssets onClick={onClickInvest}>
            <Icon
              type="RightSmall"
              size={18}
              theme="outline"
              fill={theme.colors.BLUE700}
            />
            <Text color="BLUE700" type="S18" as="div">
              Invest Now
            </Text>
          </EditAssets>
        </Card>
      </CardExpander>
    </AssetBreakdownContainer>
  );
};
