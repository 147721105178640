import React from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { ValidationMessage, FooterNotes } from "../form/card/card-styling";
import { ChangePasswordSchema } from "./ChangePassword.schema";
import {
  FieldContainer,
  FormSubHeading,
  StyledField,
} from "./ChangePassword.styles";
import { Layout } from "../../layouts";
import { SEO } from "../seo";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { navigate } from "gatsby";
import { Text } from "@ifgengineering/component-library";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import { changePassword } from "@components/Login/actions";
import { RootState } from "@Wealth/state/rootReducer";

const ButtonContainer = styled.div`
  margin: 1rem 0rem;
`;

const StyledLayout = styled(Layout)`
  justify-content: center;
`;

// Shape of form values
type ChangePasswordType = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const ChangePassword: React.FC = () => {
  const initialValues: ChangePasswordType = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const user = useSelector((state: RootState) => state.auth.user);
  if ((user && user.googleID) || user.facebookID) {
    navigate("/app/compare");
    return null;
  }

  return (
    <StyledLayout>
      <SEO title="Change Password" />
      <Text type="H32" color="SLATE900">
        Change Password
      </Text>
      <FormSubHeading>
        Enter your new password below. If you signed up without a password, you
        can create one using &apos;Set Password&apos; on the login page.
      </FormSubHeading>
      <Formik
        initialValues={initialValues}
        validationSchema={ChangePasswordSchema}
        onSubmit={async (values) => {
          try {
            await changePassword({
              password: values.currentPassword,
              newPassword: values.newPassword,
            });

            toast.success("You've successfully changed your password!");
          } catch (err) {
            if (err.response.status === 401) {
              navigate("/auth/login");
            } else if (err.response.status === 422) {
              toast.warn(
                "Try changing your password using 'Set Password' on the login page."
              );
            } else {
              toast.error("Something went wrong. Please try again later");
            }
          }
        }}
      >
        {({ errors, touched }) => {
          return (
            <Form>
              <FieldContainer>
                <StyledField
                  type="password"
                  id="currentPassword"
                  name="currentPassword"
                  placeholder="Current Password"
                />
                <ValidationMessage>
                  {errors.currentPassword && touched.currentPassword && (
                    <div>{errors.currentPassword}</div>
                  )}
                </ValidationMessage>
                <StyledField
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  placeholder="New Password"
                />
                <ValidationMessage>
                  {errors.newPassword && touched.newPassword && (
                    <div>{errors.newPassword}</div>
                  )}
                </ValidationMessage>
                <StyledField
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm New Password"
                />{" "}
                <ValidationMessage>
                  {errors.confirmPassword && touched.confirmPassword && (
                    <div>{errors.confirmPassword}</div>
                  )}
                </ValidationMessage>
              </FieldContainer>

              <ButtonContainer>
                <FormButton type="submit" text="Submit" />
              </ButtonContainer>
            </Form>
          );
        }}
      </Formik>
      <FooterNotes>
        <p>
          <FormButton
            text=""
            leftArrowLabel="Back to Discover"
            leftArrowOnClick={() => navigate("/app/compare")}
          />
        </p>
      </FooterNotes>
    </StyledLayout>
  );
};
