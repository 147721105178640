import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import React from "react";
import styled from "styled-components";
import InsightCTA from "./InsightCTA.component";
import { InsightCTAProps } from "./InsightCTA.component";

const InsightCTAsContainer = styled.div<{ itemsLength: number }>`
  overflow-x: auto;
  display: flex;
  gap: 24px;
  & > div:nth-child(1) {
    width: ${({ itemsLength }) => itemsLength < 2 && "100%"};
  }
  @media ${MEDIA_QUERIES.PHONE} {
    flex-direction: column;
    overflow: hidden;
  }
`;

interface InsightCTAsProps {
  items: InsightCTAProps[];
}

const InsightCTAs: React.FC<InsightCTAsProps> = ({ items }) => {
  return (
    <InsightCTAsContainer itemsLength={items.length}>
      {items.map((item, idx) => (
        <InsightCTA key={idx} {...item} />
      ))}
    </InsightCTAsContainer>
  );
};

export default InsightCTAs;
