import React from "react";
import { theme } from "@styles/theme";
import Icon, { IconType, Theme } from "@icon-park/react/es/all";
import { Text, MEDIA_QUERIES } from "@ifgengineering/component-library";
import styled from "styled-components";

const ChipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  & > span {
    position: absolute;
  }
`;

const ChipContainer = styled.div<{
  backgroundColor: keyof typeof theme.colors;
  rounded?: boolean;
}>`
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: ${({ backgroundColor }) => theme.colors[backgroundColor]};
  padding: 18px 24px;
  border-radius: ${({ rounded }) => (rounded ? "50px" : "8px")};
  width: fit-content;
  @media ${MEDIA_QUERIES.PHONE} {
    padding: 18px;
  }
`;

const ComingSoonLabel = styled.span`
  border-radius: 4px;
  padding: 1px 4px;
  background-color: ${theme.colors.SLATE700};
  color: ${theme.colors.WHITE}
  position: absolute;
  bottom: 0;
  right: 0;
`;
export interface ChipProps {
  icon?: IconType;
  iconTheme?: Theme;
  iconSize?: number;
  rounded?: boolean;
  color?: keyof typeof theme.colors;
  backgroundColor?: keyof typeof theme.colors;
  text: string;
  comingSoon?: boolean;
}

const Chip: React.FC<ChipProps> = ({
  text,
  backgroundColor = "PEBBLE500",
  color = "BLACK",
  icon,
  iconSize = 24,
  iconTheme,
  rounded,
  comingSoon,
}) => {
  return (
    <ChipWrapper>
      {comingSoon && (
        <ComingSoonLabel>
          <Text type="P12" color="WHITE">
            coming soon
          </Text>
        </ComingSoonLabel>
      )}
      <ChipContainer backgroundColor={backgroundColor} rounded={rounded}>
        {icon && (
          <Icon
            type={icon}
            size={iconSize}
            theme={iconTheme}
            fill={theme.colors[color]}
          />
        )}
        <Text type="S20" color={color}>
          {text}
        </Text>
      </ChipContainer>
    </ChipWrapper>
  );
};

export default Chip;
