import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import { theme } from "@styles/theme";
import styled from "styled-components";

export const HeaderSectionContainer = styled.header`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.PEBBLE100};
  padding: 3em 6em;
  gap: 3em;
  overflow-x: hidden;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    padding: 3em 2em;
  }
  @media ${MEDIA_QUERIES.PHONE} {
    padding: 3em 1em;
  }
`;

export const DiscoverContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > img {
    max-width: 450px;
    height: auto;
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    & > a > button {
      width: fit-content;
      padding: ${({ isMobile }) => (isMobile ? "0.8em 40px" : "0.8em 72px")};
      @media ${MEDIA_QUERIES.PHONE} {
        width: 100%;
      }
    }
  }
  @media ${MEDIA_QUERIES.TAB_LAND} {
    & > img {
      width: 50vw;
      height: auto;
    }
  }
  @media ${MEDIA_QUERIES.PHONE} {
    flex-direction: column-reverse;
    gap: 1em;
    text-align: center;
    & > img {
      width: 70vw;
      height: auto;
    }
  }
`;

export const HeaderTextsContainer = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  width: 40vw;
  & > span:nth-child(2) {
    width: 85%;
    @media ${MEDIA_QUERIES.PHONE} {
      width: 100%;
    }
  }
  @media ${MEDIA_QUERIES.PHONE} {
    width: 100%;
    gap: 40px;
  }
`;

export const InvestmentTypesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1em;
  flex-flow: row wrap;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    gap: 1em;
    justify-content: space-around;
  }
`;

export const Cur8StatsContainer = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  gap: 2em;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    align-self: stretch;
  }
  @media ${MEDIA_QUERIES.PHONE} {
    flex-direction: column;
    justify-content: center;
  }
`;
