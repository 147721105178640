export const trackTabs = [
  {
    title: "Net Worth",
    id: "track",
  },
  {
    title: "Insights",
    id: "insights",
  },
  {
    title: "Update Assets",
    id: "assets",
  },
  {
    title: "Personal Finance",
    id: "personal-finance",
  },
];
