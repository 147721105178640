import hugs from "@assets/charityLogos/hugs.png";
import mcb from "@assets/charityLogos/mcb.png";
import cmc from "@assets/charityLogos/cmc.png";
import fosis from "@assets/charityLogos/fosis.png";
import mrdf from "@assets/charityLogos/mrdf.png";
import cage from "@assets/charityLogos/cage.png";
import muslimCharity from "@assets/charityLogos/muslim-charity.png";
import ummahWelfareTrust from "@assets/charityLogos/ummah-welfare-trust.png";
import fivePillars from "@assets/charityLogos/5-pillars.png";
import ramadanTent from "@assets/charityLogos/ramadan-tent.png";
import muslimHands from "@assets/charityLogos/muslim-hands.png";

export const mockCharities = [
  {
    name: "Islamic Relief",
    logoUrl:
      "https://ik.imagekit.io/mubarakshow/ifg/charities/islamicrelief_logo_dfHkTqywVb.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1635837485708",
  },
  {
    name: "NZF",
    logoUrl:
      "https://www.totalgiving.co.uk/dm-images/charity-logos/large/nationalzakatfoundationnzf.jpg",
  },
  {
    name: "Muslim Charity",
    logoUrl: muslimCharity,
  },
  {
    name: "Muslim Hands",
    logoUrl: muslimHands,
  },
  {
    name: "Muslim Aid",
    logoUrl:
      "https://ik.imagekit.io/mubarakshow/ifg/charities/muslimaid_logo_kCTrBNrPPZDL.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1635837486042",
  },
  {
    name: "FOSIS",
    logoUrl: fosis,
  },
  {
    name: "UWT",
    logoUrl: ummahWelfareTrust,
  },
  {
    name: "Solace",
    logoUrl:
      "https://muslimgivingproduction.blob.core.windows.net/muslimgiving-production/public/charity/53/logo/0dde0e446ba41b3ff807e6d3a11dd051-bb6e4a10f79cd9b3d338e97c05e52eb0.png",
  },
  {
    name: "MRDF",
    logoUrl: mrdf,
  },
  {
    name: "HHUGs",
    logoUrl: hugs,
  },
  {
    name: "MCB",
    logoUrl: mcb,
  },
  {
    name: "Zahra Trust",
    logoUrl:
      "https://pbs.twimg.com/profile_images/1265938472/zahra_400x400.jpg",
  },
  {
    name: "CAGE",
    logoUrl: cage,
  },
  {
    name: "MEND",
    logoUrl: "https://www.asianimage.co.uk/resources/images/7580989/",
  },
  {
    name: "CMC",
    logoUrl: cmc,
  },
  {
    name: "Muslim Youth Helpline",
    logoUrl:
      "https://s3.amazonaws.com/launchgood/sponsor%2F1702%2Fmuslim_youth_helpline_logo+launchgood-180x180.png",
  },
  {
    name: "Ramadan Tent",
    logoUrl: ramadanTent,
  },
  {
    name: "Five Pillars",
    logoUrl: fivePillars,
  },
  {
    name: "Islam 21c",
    logoUrl: "https://www.islam21c.com/wp-content/uploads/i21c-logo-square.png",
  },
  {
    name: "Eden care and UK Muslim burial fund",
    logoUrl:
      "https://muslimgiving-production-cdn.azureedge.net/muslimgiving-production/public/campaign/999/logo/616469a82ba5a9f6d97ce8dad0bc9cc5-10476ed623558f44a3ba98beac4e08f0.png",
  },
];
