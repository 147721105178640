import styled from "styled-components";
import { MEDIA_QUERIES, theme } from "@ifgengineering/component-library";

export const StyledFoter = styled.div`
  background: ${theme.colors["SLATE100"]};
  padding: 24px 104px;
  @media ${MEDIA_QUERIES.PHONE} {
    padding: 24px 16px;
  }
`;
