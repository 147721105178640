import styled from "styled-components";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export default Container;
