import React, { useEffect, useState } from "react";
import InsightDetailPageComponent from "@components/InsightDetailPage/InsightDetailPageComponent.component";
import { useInsightsData } from "@Wealth/hooks/useInsightsData";
import InsightsNavigation from "@components/InsightNavigation/InsightsNavigation.component";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES, Text } from "@ifgengineering/component-library";
import { theme } from "@styles/theme";
import { navigate } from "gatsby";
import FilledButton from "@components/Button/FilledButton.component";
import ListCheckboxIcon from "@icon-park/react/lib/icons/ListCheckbox";
import CloseIcon from "@icon-park/react/lib/icons/Close";
import Modal from "../Modal/Modal.component";
import {
  DetailContainer,
  DisclaimerContainer,
  FooterContainer,
  HeaderContainer,
  InsightsWrapper,
  MobileNavButton,
  MobileNavContainer,
  NavContainer,
  SurveyContainer,
  TrustpilotReviewContainer,
} from "./styled";
import Loading from "@components/Loading";
import { useSelector } from "react-redux";
import { getInsightsLoading } from "@Wealth/state/insights/insights.selectors";
import * as queryString from "query-string";
import useAnalytics from "@helpers/useAnalytics";

const Insights: React.FC = () => {
  const isTablet = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });
  const { insights } = useInsightsData();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const params = queryString.parse(location.search);

  const isInsightsLoading = useSelector(getInsightsLoading);

  const firePageViewAnalytics = useAnalytics("pageView");

  const handleNavSelect = (val: { category: string; section: string }) => {
    const section = document.getElementById(val.section);
    const category = document.getElementById(val.category);
    if (!val.section || !val.section.length) {
      category?.scrollIntoView({ behavior: "smooth" });
    } else {
      section?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToInsight = (id: string) => {
    const insightElem = document.getElementById(id);
    insightElem?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({ eventName });
    };

    firePageView("track:insightsReport_pageViewed");
  }, []);

  useEffect(() => {
    if (params.insight && !isInsightsLoading) {
      scrollToInsight(params.insight as string);
    }
  }, [params.insights, isInsightsLoading]);

  if (isInsightsLoading) return <Loading />;

  return (
    <InsightsWrapper>
      {!isTablet && (
        <NavContainer>
          <InsightsNavigation data={insights} onChange={handleNavSelect} />
        </NavContainer>
      )}

      {isTablet && (
        <MobileNavButton onClick={() => setIsOpen(true)}>
          <ListCheckboxIcon fill={theme.colors.WHITE} />
        </MobileNavButton>
      )}

      {isTablet && (
        <Modal
          open={isOpen}
          closeOnOutsideClick={true}
          onClose={() => setIsOpen(false)}
        >
          <MobileNavContainer>
            <InsightsNavigation data={insights} onChange={handleNavSelect} />
            <button onClick={() => setIsOpen(false)}>
              <CloseIcon fill={theme.colors.WHITE} />
            </button>
          </MobileNavContainer>
        </Modal>
      )}

      <DetailContainer>
        <HeaderContainer>
          <Text type="T48" color="BLACK">
            Insights
          </Text>
          <Text type="P18" color="SLATE800">
            The following insights are based on your assets and the answers you
            have provided to questions. Please keep this information up to date
            get the most out of this tool -{" "}
            <Text
              as="a"
              type="P16"
              color="BLUE700"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("?view=assets")}
            >
              Update now
            </Text>
          </Text>
          <DisclaimerContainer>
            <Text type="P16" color="SLATE700">
              This feedback should not be seen as individualised financial
              advice, e.g. on the merits of specific investment strategies. If
              in any doubt, consult a financial advisor. Remember, when
              investing your capital is at risk.
            </Text>
          </DisclaimerContainer>
        </HeaderContainer>
        <InsightDetailPageComponent insights={insights} />
        <FooterContainer>
          <Text type="T48" color="BLUE800">
            Final Words
          </Text>
          <TrustpilotReviewContainer>
            <Text type="P18" color="SLATE800">
              There you have it! Your free portfolio health check! 😃
            </Text>
            <Text type="P18" color="SLATE800">
              If you found this useful, we would love if you could leave us a
              nice review on Trustpilot here:
            </Text>
            <FilledButton
              text="Leave us a Review"
              color="blue"
              icon="Star"
              iconTheme="filled"
              iconColor={theme.colors.T300}
              href="https://uk.trustpilot.com/review/islamicfinanceguru.com"
              style={{
                background: theme.colors.WHITE,
                border: `solid 2px ${theme.colors.SLATE300}`,
              }}
            />
          </TrustpilotReviewContainer>
          <SurveyContainer>
            <Text type="P18" color="SLATE800">
              An incredible amount of work has gone into producing the these
              insights and <strong>we&apos;d love your support!</strong>
            </Text>
            <Text type="P18" color="SLATE800">
              If you do have any feedback on things we can improve we would love
              to hear from you - and insights will be constantly improved over
              the coming months.
            </Text>
            <Text type="P18" color="SLATE800">
              Please complete the survey below:
            </Text>
            <FilledButton
              text="Complete survey"
              color="blue"
              icon="Message"
              iconColor={theme.colors.SLATE800}
              href="https://islamicfinanceguru.typeform.com/to/gFH3AVlW"
              style={{
                background: theme.colors.WHITE,
                border: `solid 2px ${theme.colors.SLATE300}`,
              }}
            />
          </SurveyContainer>
        </FooterContainer>
      </DetailContainer>
    </InsightsWrapper>
  );
};

export default Insights;
