import React, { FC } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Text,
  TextTypeAndSize,
  MEDIA_QUERIES,
} from "@ifgengineering/component-library";
import { StyledFoter } from "./styled";

export const TrackFooter: FC = () => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const textSize: TextTypeAndSize = isMobile ? "P12" : "P16";

  return (
    <StyledFoter>
      <Text color="SLATE700" type="H20">
        Disclaimer
      </Text>
      <Text color="SLATE700" type={textSize}>
        The Track tool is a high level personal finance tool and should not be
        construed as financial advice. The content on this website is for
        informational purposes only, it is information of a general nature and
        does not address the circumstances of any particular individual or
        entity.
      </Text>
    </StyledFoter>
  );
};
