import React from "react";
import { theme } from "@styles/theme";
import styled from "styled-components";
import { Text, MEDIA_QUERIES } from "@ifgengineering/component-library";
import FilledButton from "@components/Button/FilledButton.component";
import useAnalytics from "@helpers/useAnalytics";

const FooterSectionContainer = styled.footer`
  display: flex;
  padding: 3em 6em;
  justify-content: center;
  gap: 4em;
  overflow-x: hidden;
  align-items: center;
  background-color: ${theme.colors.SLATE900};
  & h1 {
    max-width: 450px;
  }
  & > a {
    width: 100%;
    max-width: 288px;
    & > button {
      color: black;
      & span {
        color: black;
      }
    }
  }
  @media ${MEDIA_QUERIES.TAB_PORT} {
    padding: 3em 2em;
    gap: 3em;
  }
  @media ${MEDIA_QUERIES.PHONE} {
    padding: 3em 1em;
    flex-direction: column;
    text-align: center;
    gap: 1.5em;
  }
`;

const FooterSection = () => {
  const fireCur8JoinClickAnalytics = useAnalytics("cur8JoinClick");

  return (
    <FooterSectionContainer>
      <Text type="T32" color="WHITE" fontFamily="archiaregular">
        Start investing in high growth opportunities today
      </Text>
      <FilledButton
        color="red"
        text="Join Cur8"
        icon="ArrowRight"
        iconPosition="right"
        textSize="S16"
        href="https://ifg.vc/"
        style={{ background: theme.colors.GREEN400 }}
        onClick={async () => (await fireCur8JoinClickAnalytics)()}
      />
    </FooterSectionContainer>
  );
};

export default FooterSection;
