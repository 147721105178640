import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUserData } from "./useUserData";
import store from "../state";
import { updatePortfolio } from "@components/PortfolioData/actions";
import { fetchPortfolioAPI } from "@components/PortfolioData/actions/portfolio";
import { PortfolioDataInterface } from "@ifgengineering/hip-app-domain";
import { getPortfolioData } from "@components/PortfolioData/selectors";

export const usePortfolioData = () => {
  const [loading, setLoading] = useState(true);
  const [portfolio, setPortfolio] = useState<PortfolioDataInterface>({
    currency: "GBP",
    version: new Date(),
  });
  const dispatch = useDispatch<typeof store.dispatch>();
  const { user } = useUserData();
  const storePortfolioData = useSelector(getPortfolioData);

  useEffect(() => {
    // Initialize with the data we already have on the Redux Store
    setPortfolio(storePortfolioData);
  }, [storePortfolioData]);

  useEffect(() => {
    if (user?.email) {
      const fetchPortfolio = async () => {
        setLoading(true);

        const { data } = await dispatch(
          fetchPortfolioAPI(user?.email)
        ).unwrap();

        if (data) {
          dispatch(updatePortfolio(data));
          setPortfolio(data);
          setLoading(false);
          return data;
        }

        setLoading(false);
      };

      fetchPortfolio();
    }
  }, [user]);

  return { portfolio, loading };
};
