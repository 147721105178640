import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { WillInterface } from "@ifgengineering/hip-app-domain";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";

export const FETCH_WILLS_API = "FETCH_WILLS_API";

export const fetchWillsAPI = createAsyncThunk<WillInterface, string>(
  FETCH_WILLS_API,
  async (email) => {
    try {
      const response = await client.query({
        fetchPolicy: "no-cache",
        query: gql`
          query ($email: String!) {
            findWill(email: $email) {
              id
              status
            }
          }
        `,
        variables: {
          email,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }

      return response.data.findWill;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        email,
        tag: "fetchWillsAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
