import React from "react";
import { Text } from "@ifgengineering/component-library";
import {
  Container,
  Button,
  Heading,
  SpaceBetween,
  Description,
} from "./styled";
import { InsightType } from "@components/Insight/constants/types";
import Card from "@components/Card";
import InsightTag from "@components/InsightTag/InsightTag.component";
import { isInsightsEnabled } from "@helpers/useFeatureFlag";

const Insight: React.FC<InsightType> = ({
  title,
  description,
  tagType,
  onClick,
}) => {
  return (
    <Container>
      <Card>
        <SpaceBetween>
          <Heading>
            {title && (
              <Text type="H18" color={"SLATE800"}>
                {title}
              </Text>
            )}
            <InsightTag tagType={tagType} />
          </Heading>
          <Description type="P16" color={"SLATE800"}>
            {description}
          </Description>
          {isInsightsEnabled && <Button onClick={onClick}>View Insight</Button>}
        </SpaceBetween>
      </Card>
    </Container>
  );
};

export default Insight;
