import styled from "styled-components";
import { Field } from "formik";

export const CardContainer = styled.div`
  text-align: center;
  margin: 8rem;
  position: relative;
`;

export const FooterNotes = styled.div`
  margin: 2rem;
  text-align: center;
`;

export const CardWrapper = styled.div`
  overflow: hidden;
  margin: 0rem auto 0;
  font-family: Inter;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
`;

export const CardHeader = styled.header`
  padding-top: 3.2rem;
`;

export const CardHeading = styled.h1`
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
`;

export const CardBody = styled.div``;

export const CardFieldset = styled.fieldset`
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;

  & + & {
    margin-top: 2.4rem;
  }

  &:nth-last-of-type(2) {
    margin-top: 3.2rem;
  }

  &:last-of-type {
    text-align: center;
  }
`;

export const CardInput = styled(Field)`
  padding: 1.4rem 2rem;
  width: 100%;
  height: 7.1rem;
  font-family: inherit;
  font-size: var(--default-font-size);
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #ddd;
  border-left: 0;
  transition: border-bottom-color 0.25s ease-in;

  &:focus {
    border-bottom-color: #e5195f;
    outline: 0;
  }
`;

export const ValidationMessage = styled.label`
  margin-bottom: 0.5em;
  color: #ac0a40;
  display: block;
  font-size: var(--small-font-size);
  text-align: center;
`;
