import React from "react";
import Insight from "@components/Insight/Insight.component";
import { ScrollContainer } from "./styled";
import { InsightScrollType } from "./constants/types";

const InsightScroll: React.FC<InsightScrollType> = ({ data }) => {
  return (
    <ScrollContainer>
      {data.map((insight, key) => (
        <Insight key={key} {...insight} />
      ))}
    </ScrollContainer>
  );
};

export default InsightScroll;
