import React from "react";
import styled from "styled-components";
import { Text, MEDIA_QUERIES } from "@ifgengineering/component-library";

const Container = styled.div`
  & > img {
    width: auto;
    height: 100px;
    margin-bottom: 1em;
  }
  @media ${MEDIA_QUERIES.PHONE} {
    display: grid;
    grid-template-columns: 40vw auto;
    & > img {
      width: 80px;
      height: auto;
      margin-bottom: 0;
    }
    & > div {
      max-width: 184px;
    }
  }
`;

export interface IllustrationWithTextsProps {
  image: string;
  title: string;
  description?: string;
}

const IllustrationWithTexts: React.FC<IllustrationWithTextsProps> = ({
  title,
  description,
  image,
}) => (
  <Container>
    <img src={image} alt={title} />
    <div>
      <Text color="BLACK" type="T48" fontFamily="archiasemibold">
        {title}
      </Text>
      {description && (
        <Text color="BLACK" type="S16">
          {description}
        </Text>
      )}
    </div>
  </Container>
);

export default IllustrationWithTexts;
