import styled from "styled-components";
import background from "@assets/images/track/zakat-background.png";
import { theme } from "../../../styles/theme";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";

export const Page = styled.div`
  background: url(${background}) #eaeef1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  background-repeat: repeat no-repeat;
`;

interface HeaderTextProps {
  isMobile: boolean;
}

// TODO: Fix me after adding the new types to the typography object.
export const HeaderText = styled.div<HeaderTextProps>`
  font-weight: 400;
  font-size: ${({ isMobile }) => (isMobile ? "24" : "48")}px;
  line-height: ${({ isMobile }) => (isMobile ? "40" : "64")}px;
  letter-spacing: -1px;
  color: ${theme.colors.SLATE800};
  @media ${MEDIA_QUERIES.PHONE} {
    padding: 2rem;
  }
`;

export const StartUsingTrackContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 3rem 0;
  & > div {
    max-width: 496px;
  }
  & img {
    width: 496px;
  }

  @media ${MEDIA_QUERIES.PHONE} {
    flex-direction: column;
    padding-top: 2rem;

    margin: 0;
    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    & img {
      width: 80%;
    }
  }
`;

export const StartUsingTrackText = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: column;
  margin-left: 2rem;

  & > button {
    padding: 1rem;
    max-width: 300px;
  }

  @media ${MEDIA_QUERIES.PHONE} {
    gap: 0;
    margin-left: 0;

    & > button {
      padding: 1rem;
      max-width: 80%;
      margin: 0 0 2rem 0;
    }
  }
`;

export const PortfolioManagementContainer = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${MEDIA_QUERIES.PHONE} {
    padding: 2rem;
    width: 100%;
  }
`;

export const PortfolioManagementText = styled.div`
  max-width: 570px;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  text-align: center;

  @media ${MEDIA_QUERIES.PHONE} {
    width: 100%;
    max-width: 100%;
    text-align: left;
  }
`;

export const InformedDecisionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 6rem;
  max-width: 1280px;

  & div:nth-of-type(2) {
    margin-left: 9rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  & img {
    max-width: 700px;
  }

  @media ${MEDIA_QUERIES.PHONE} {
    flex-direction: column;
    margin: 0;
    max-width: 100%;
    padding: 2rem;

    & div:nth-of-type(2) {
      margin-left: 0;
    }

    & img {
      max-width: 100%;
    }
  }
`;

export const ManageRiskContainer = styled.div`
  display: flex;
  max-width: 1000px;
  align-items: center;
  margin: 3rem 5rem;
  justify-content: space-between;

  & > div:nth-of-type(1) {
    margin-right: 11rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  & img {
    max-width: 470px;
  }

  @media ${MEDIA_QUERIES.PHONE} {
    flex-direction: column-reverse;
    max-width: 100%;
    margin: 0;
    padding: 2rem;

    & > div:nth-of-type(1) {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin: 1rem 0 0 0;
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & img {
      max-width: 70%;
    }
  }
`;

export const ZakatWithOneClickContainer = styled.div`
  max-width: 1000px;
  display: flex;
  align-items: center;
  gap: 5rem;
  margin: 6rem;

  & div:nth-of-type(2) {
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }

  @media ${MEDIA_QUERIES.PHONE} {
    max-width: 100%;
    gap: 0;
    margin: 0;
    padding: 2rem;
    flex-direction: column;

    & div:nth-of-type(1) {
      width: 80%;
    }

    & div:nth-of-type(2) {
      margin-top: 2rem;
      gap: 1rem;
      min-width: 100%;
    }
  }
`;

export const Footer = styled.div`
  background: url(${background}) ${theme.colors.SLATE800};
  display: flex;
  width: 100%;
  padding: 5rem 0;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  h1 {
    white-space: pre;
  }

  button {
    margin-left: 5rem;
    padding: 1rem;
    span {
      color: ${theme.colors.BLUE900};
    }
  }

  & > div {
    display: flex;
    width: 700px;
    align-items: center;
  }

  @media ${MEDIA_QUERIES.PHONE} {
    padding: 2rem;
    gap: 0;

    h1 {
      white-space: normal;
    }

    button {
      padding: 1rem;
      margin: 3rem 0 0 0;
    }

    & > div {
      flex-direction: column;
      width: 100%;
    }
  }
`;
