import styled from "styled-components";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 550px;
  width: 300px;
  flex-wrap: wrap;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    width: 360px;
  }
  @media ${MEDIA_QUERIES.PHONE} {
    max-height: 100%;
  }
`;

export default LegendWrapper;
