import React from "react";
import styled from "styled-components";
import bgImage from "@assets/images/zakat-bg.png";
import { Text } from "@ifgengineering/component-library";
import FilledButton from "@components/Button/FilledButton.component";
import PartnerCharityChipList from "@components/PartnerCharityChip/PartnerCharityChipList.component";
import { mockCharities } from "@components/PartnerCharityChip/mockCharities";
import { SEO } from "../seo";
import { ZAKAT_SITE_URL } from "gatsby-env-variables";

const ZakatLandingPageContainer = styled.div``;

const BackgroundImage = styled.section`
  background-image: url("${bgImage}");
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  z-index: 3;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6.5em 12vw;
  text-align: justify;
  gap: 2em;
  @media (max-width: 846px) {
    padding: 3.5em 1.5em;
    height: auto;
    text-align: left;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  max-width: 400px;
  gap: 2em;
  & > button {
    width: 80%;
  }
  & > p {
    text-align: left;
    font-weight: 200;
    line-height: 1.6;
  }
  @media (max-width: 846px) {
    width: 100%;
    & > button {
      width: 100%;
    }
  }
`;

const DescriptionContainer = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 2em;

  @media (max-width: 846px) {
    width: 100%;
  }
`;

const ZakatPartnerCharitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  max-width: 1400px;

  @media (max-width: 846px) {
    width: 100%;
    & > h3 {
      font-size: 16px;
    }
  }
`;

const ZakatLanding = () => (
  <ZakatLandingPageContainer>
    <SEO title="Zakat Calculator | Easily Calculate Your Zakat" />
    <BackgroundImage>
      <ContentContainer>
        <Text color="WHITE" type="T32">
          Zakat Calculator
        </Text>
        <DescriptionContainer>
          <Text color="WHITE" type="P16">
            {
              "Our zakat calculator caters for a huge range of assets that traditional calculators don't - no more approximations."
            }
          </Text>

          <Text color="WHITE" type="P16">
            {`→ After calculating your zakat you can get a free portfolio health
              check with our track feature - you'll inshAllah end up saving far
              more than you donate if you follow the insights.`}
          </Text>

          <Text color="WHITE" type="P16">
            → Get free access to the Halal Money Formula course worth £278.40 if
            you refer 4 new customers and they use the zakat calculator too -
            built in barakah!
          </Text>

          <Text color="WHITE" type="P16">
            → For more information on what assets to include and how we
            calculate your zakat please read our detailed{" "}
            <Text
              as="a"
              color="BLUE300"
              href="https://www.islamicfinanceguru.com/zakatcalculatorguidance"
              target="_blank"
              type="S16"
            >
              guide
            </Text>{" "}
            or watch our{" "}
            <Text
              as="a"
              color="BLUE300"
              href="https://www.youtube.com/watch?v=7j506s0N5dM"
              target="_blank"
              type="S16"
            >
              tutorial
            </Text>
            .
          </Text>
        </DescriptionContainer>
        <ButtonContainer>
          <FilledButton
            text="Calculate My Zakat"
            color="blue"
            href={ZAKAT_SITE_URL}
            target="_self"
          />
          <Text color="SLATE300" type="P14">
            We are providing this zakat calculator purely as a community
            service. We will not be taking any admin fees
          </Text>
        </ButtonContainer>
        <ZakatPartnerCharitiesContainer>
          <Text color="WHITE" type="T18">
            CHARITIES & NGOs THAT YOU CAN DONATE TO
          </Text>
          <PartnerCharityChipList charities={mockCharities} />
        </ZakatPartnerCharitiesContainer>
      </ContentContainer>
    </BackgroundImage>
  </ZakatLandingPageContainer>
);

export default ZakatLanding;
