import React from "react";
import { Text } from "@ifgengineering/component-library";
import { VCLogos } from "../data";
import {
  LogosContainer,
  TextContainer,
  VCPartnersSectionContainer,
} from "./styled";

const VCPartnersSection: React.FC = () => {
  return (
    <VCPartnersSectionContainer>
      <TextContainer>
        <Text type="T32" fontFamily="archiasemibold" color="SLATE900">
          Access Exclusive Investments
        </Text>
        <div>
          <Text type="S16" color="SLATE700">
            We invest alongside the best venture capitalists in the industry.
          </Text>
        </div>
      </TextContainer>
      <LogosContainer>
        {VCLogos.map(({ companyName, image }) => (
          <img key={companyName} src={image} alt={companyName} />
        ))}
      </LogosContainer>
    </VCPartnersSectionContainer>
  );
};

export default VCPartnersSection;
