import styled from "styled-components";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import { theme } from "@styles/theme";

export const Container = styled.div`
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  flex-direction: column;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    background-color: ${theme.colors.SLATE800};
    width: 100%;
  }
`;

export const SectionItemWrapper = styled.div`
  padding: 16px 0;
  user-select: none;
`;

export const TextWrapper = styled.div`
  cursor: pointer;
  height: 20px;
  display: flex;
  align-items: center;
`;
