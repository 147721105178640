import FilledButton from "@components/Button/FilledButton.component";
import { Text, MEDIA_QUERIES } from "@ifgengineering/component-library";
import React from "react";
import { useMediaQuery } from "react-responsive";
import {
  DiscoverContainer,
  HeaderSectionContainer,
  HeaderTextsContainer,
  Cur8StatsContainer,
  InvestmentTypesContainer,
} from "./styled";
import AstronautImage from "@assets/images/astronaut.png";
import { illustrationWithTextsData, investmentChips } from "../data";
import IllustrationWithTexts from "../IllustrationWithTexts.component";
import Chip from "@components/Chip/Chip.component";
import useAnalytics from "@helpers/useAnalytics";

const HeaderSection = () => {
  const isTablet = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const fireCur8JoinClickAnalytics = useAnalytics("cur8JoinClick");

  return (
    <HeaderSectionContainer>
      <DiscoverContainer isMobile={isTablet}>
        <div>
          <HeaderTextsContainer>
            <Text
              type={isTablet ? "T32" : "T48"}
              fontFamily={isTablet ? "archiaregular" : "archiasemibold"}
              color="SLATE800"
            >
              Discover your next High-Growth Investment.
            </Text>
            <Text type="S20" color="SLATE700">
              Invest in tier 1 Startups and Real Estate - all pre-vetted &
              chosen by the IFG team.
            </Text>
          </HeaderTextsContainer>
          <FilledButton
            text="Take me to Cur8"
            textSize={isMobile ? "H18" : "H20"}
            color="blue"
            icon="ArrowRight"
            iconPosition="right"
            href="https://ifg.vc/"
            onClick={async () => (await fireCur8JoinClickAnalytics)()}
          />
        </div>
        <img src={AstronautImage} alt="astronaut" />
      </DiscoverContainer>
      <InvestmentTypesContainer>
        {investmentChips.map((type) => (
          <Chip key={type.text} {...type} iconSize={isTablet ? 20 : 24} />
        ))}
      </InvestmentTypesContainer>
      <Cur8StatsContainer>
        {illustrationWithTextsData.map((item) => (
          <IllustrationWithTexts key={item.title} {...item} />
        ))}
      </Cur8StatsContainer>
    </HeaderSectionContainer>
  );
};

export default HeaderSection;
