import { RouteComponentProps } from "@reach/router";
import React from "react";
import { SEO } from "../seo/seo";

type Props = RouteComponentProps;

const Verified: React.FC<Props> = () => {
  return (
    <div>
      <SEO title="Verified Email" />
      <h2>Your email has been verified!</h2>
    </div>
  );
};

export default Verified;
