import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInsightCategories,
  getInsights,
  getInsightsLoading,
} from "@Wealth/state/insights/insights.selectors";
import { fetchUserInsights } from "@Wealth/state/insights/insights.actions";
import { getUserSelector } from "@Wealth/state/user/user.selectors";

export const useInsightsData = (limit?: number) => {
  const insights = useSelector(getInsights) || [];
  const insightCategories = useSelector(getInsightCategories);
  const isLoading = useSelector(getInsightsLoading);
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);

  useEffect(() => {
    if (user.email) {
      dispatch(fetchUserInsights({ limit }));
    }
  }, [user.email]);

  return { insights, insightCategories, isLoading };
};
