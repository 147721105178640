import React, { useState } from "react";
import { PieChart, Pie, Cell, Legend, LegendType } from "recharts";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import LegendWrapper from "./styled/LegendWrapper";
import LegendComponent from "./styled/LegendComponent";
import activeShape from "./shapes/activeShape";
import COLORS from "./constants/pieColors";
import { Props, Payload } from "recharts/src/component/DefaultLegendContent";
import Container from "@components/PieChart/styled/Container";

type PieProps = {
  data: {
    name: string;
    value: number;
  }[];
};

type CustomProp = {
  hoverAction: (index: number | undefined) => void;
};

const RenderLegend: (props: Props & CustomProp) => React.ReactElement = (
  props: Props & CustomProp
) => (
  <LegendWrapper>
    {props.payload?.map((entry, index) => (
      <LegendComponent
        onMouseEnter={() => props.hoverAction(index)}
        onMouseOut={() => props.hoverAction(undefined)}
        key={`item-${index}`}
        color={entry.color}
        name={entry.value}
        entry={entry.payload}
      />
    ))}
  </LegendWrapper>
);

const CustomPieChart: React.FC<PieProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [content, setContent] = useState<Payload[] | undefined>(undefined);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const isTablet = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });

  const size = isTablet || isMobile ? 370 : 550;

  const onPieEnter: (data: LegendType, index?: number) => void = (
    data,
    index
  ) => setActiveIndex(index);

  const handleLegendClick: (index?: number) => void = (index) =>
    setActiveIndex(index);

  return (
    <Container>
      <RenderLegend hoverAction={handleLegendClick} payload={content} />
      <PieChart
        layout="centric"
        width={size}
        height={isTablet || isMobile ? size - 50 : size - 150}
      >
        <Pie
          activeIndex={activeIndex}
          activeShape={isMobile ? undefined : activeShape}
          data={data}
          dataKey="value"
          nameKey="name"
          outerRadius={140}
          onMouseEnter={onPieEnter}
          onMouseOut={() => setActiveIndex(undefined)}
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          layout="vertical"
          align={isMobile ? "center" : "left"}
          verticalAlign={isMobile ? "bottom" : "middle"}
          content={(props) => {
            !content && setContent(props.payload);
            return null;
          }}
        />
      </PieChart>
    </Container>
  );
};

export default CustomPieChart;
