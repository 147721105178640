import React from "react";
import { MEDIA_QUERIES, theme } from "@ifgengineering/component-library";
import styled from "styled-components";
import SampleNetworthSummary from "./SampleNetworthSummary.component";

export interface NetworthSummaryProps {
  amount: number;
  description?: string;
  formatCurrency: (x: number) => string;
  sampleData?: boolean;
  firstName?: string;
}

export const NetworthSummaryContainer = styled.div<{
  minHeight?: string;
  alignItems?: string;
  flexDirectionMobile?: string;
}>`
  background-color: ${theme.colors.BLACK};
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight || "281px"};
  position: relative;
  align-items: ${({ alignItems }) => alignItems || "center"};
  overflow-x: scroll;
  @media ${MEDIA_QUERIES.PHONE} {
    flex-direction: ${({ flexDirectionMobile }) =>
      flexDirectionMobile || "column-reverse"};
    align-items: flex-start;
  }
`;

export const Wrapper = styled.div`
  padding: 0px 6.75rem;
  @media ${MEDIA_QUERIES.PHONE} {
    padding: 0 0 20px 20px;
  }
`;

export const NetWorthAmount = styled.h2<{ removePadding?: boolean }>`
  font-weight: 600;
  font-size: 80px;
  padding-bottom: ${({ removePadding }) => (removePadding ? "0" : "20px")};
  color: ${theme.colors.WHITE};
  line-height: 64px;
  font-family: archiasemibold;
  @media ${MEDIA_QUERIES.PHONE} {
    font-weight: 400;
    font-size: 13.5vw;
    font-family: archiaregular;
  }
`;

export const DescriptionText = styled.p`
  color: ${theme.colors.SLATE400};
  font-weight: 400;
  font-size: 14px;
`;

const NetWorthSummary: React.FC<NetworthSummaryProps> = ({
  amount,
  description,
  formatCurrency,
  sampleData,
  firstName,
}) => {
  return (
    <>
      {sampleData ? (
        <SampleNetworthSummary
          amount={amount}
          firstName={firstName}
          formatCurrency={formatCurrency}
          description={description}
        />
      ) : (
        <NetworthSummaryContainer>
          <Wrapper>
            <NetWorthAmount>{formatCurrency(amount)}</NetWorthAmount>
            <DescriptionText>{description}</DescriptionText>
          </Wrapper>
        </NetworthSummaryContainer>
      )}
    </>
  );
};

export default NetWorthSummary;
