import React from "react";
import styled from "styled-components";
import { Text, MEDIA_QUERIES } from "@ifgengineering/component-library";
import { theme } from "@styles/theme";

const JoinCommunitySectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${theme.colors.SLATE100};
  padding: 3em 6em;
  gap: 1em;
  overflow-x: hidden;
  text-align: center;
  place-items: center;
  & > div {
    max-width: 800px;
    @media ${MEDIA_QUERIES.TAB_PORT} {
      width: 75vw;
      text-align: center;
    }
    @media ${MEDIA_QUERIES.PHONE} {
      width: 100%;
      text-align: left;
      text-justify: distribute;
    }
  }
  @media ${MEDIA_QUERIES.TAB_PORT} {
    text-align: left;
    padding: 3em 2em;
  }
  @media ${MEDIA_QUERIES.PHONE} {
    padding: 3em 1em;
  }
`;

const JoinCommunitySection = () => (
  <JoinCommunitySectionContainer>
    <Text type="T32" fontFamily="archiasemibold" color="BLUE600">
      Join a community of investors
    </Text>
    <div>
      <Text type="S18" color="SLATE600">
        When you join Cur8 Capital you become part of a tight-knit community of
        investors where you can network and discuss investment strategies. You
        will get access to an exclusive telegram group as well as regular
        in-person meetups.
      </Text>
    </div>
  </JoinCommunitySectionContainer>
);

export default JoinCommunitySection;
