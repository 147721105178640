import styled from "styled-components";
import { theme } from "@styles/theme";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";

export const InsightsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 4.5em 5.85rem;
  position: relative;
  @media ${MEDIA_QUERIES.TAB_LAND} {
    dislay: flex;
    padding: 4.5em 5vw;
    gap: 2em;
  }
  @media ${MEDIA_QUERIES.TAB_PORT} {
    display: flex;
    padding: 2em 1em;
  }
`;
export const NavContainer = styled.div`
  width: fit-content;
  position: relative;
  & > div {
    position: fixed;
  }
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
  gap: 3.5em;
  padding: 0 2em;
  @media ${MEDIA_QUERIES.TAB_LAND} {
    width: 100%;
    padding: 0;
  }
  @media ${MEDIA_QUERIES.TAB_PORT} {
    width: 100%;
    padding: 0;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  & > h1 {
  }
`;

export const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  background-color: ${theme.colors.WHITE};
  border-radius: 8px;
  padding: 1em;
  margin-top: 1em;
`;

export const FooterContainer = styled.div``;

export const TrustpilotReviewContainer = styled.div`
  padding: 2.5em 0;
  & > a > button {
    margin-top: 1em;
    width: fit-content;
    span {
      color: ${theme.colors.SLATE700};
    }
  }
`;

export const SurveyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  & > a > button {
    width: fit-content;
    span {
      color: ${theme.colors.SLATE700};
    }
  }
`;

export const MobileNavButton = styled.button`
  position: fixed;
  bottom: 8.5vh;
  right: 20px;
  border-radius: 50%;
  background-color: ${theme.colors.SLATE800};
  padding: 12px;
  box-shadow: box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  z-index: 1
`;

export const MobileNavContainer = styled.div`
  height: 100vh;
  background-color: ${theme.colors.SLATE800};
  color: white;
  padding: 2em 0;
  position: relative;
  & > button {
    position: fixed;
    bottom: 9.5vh;
    right: 30px;
  }
`;
