import React, { useEffect } from "react";
import { Router } from "@reach/router";
import { PageProps } from "gatsby";
import { PrivateRoute } from "../components/private-route";
import { Discover } from "../components/discover";
import { NotFound } from "../components/not-found";
import { Verified, VerificationError } from "../components/email";
import UnderConstruction from "../components/not-found/UnderConstruction.component";
import { SEO } from "../components/seo";
import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
import { getUserSelector } from "../state/user/user.selectors";
import { Layout } from "@Wealth/layouts";

type Props = PageProps;

const App: React.FC<Props> = () => {
  const user = useSelector(getUserSelector);

  useEffect(() => {
    if (user.email) {
      Sentry.setUser({ email: user.email });
    }
  }, [user]);

  return (
    <Layout>
      <SEO title="IFG | Track Your Wealth" />
      <Router basepath="/app">
        <PrivateRoute path="/compare" component={Discover} />
        <Verified path="/email/verified" />
        <VerificationError path="/email/verification-error" />
        <UnderConstruction path="/under-construction" />
        <NotFound path="/*" />
      </Router>
    </Layout>
  );
};

export default App;
