import React from "react";
import { Text, FilledButton, theme } from "@ifgengineering/component-library";
import Tooltip from "@components/Tooltip";
import { helpPageData } from "./data";
import {
  ButtonsContainer,
  GroupContainer,
  GroupsWrapper,
  GroupTitleContainer,
  PageWrapper,
  SectionContainer,
  SectionsWrapper,
} from "./styled";

const Help: React.FC = () => {
  return (
    <PageWrapper>
      <SectionsWrapper>
        {helpPageData.map((item, section_idx) => (
          <SectionContainer key={section_idx}>
            <Text type="H20" color="SLATE800">
              {item.sectionTitle}
            </Text>
            <GroupsWrapper>
              {item.groups.map((groupItem, group_idx) => (
                <GroupContainer
                  key={`section_${section_idx}__group_${group_idx}`}
                >
                  {groupItem.title && (
                    <GroupTitleContainer>
                      <Text type="S18" color="SLATE800">
                        {groupItem.title}
                      </Text>
                      {groupItem.info && (
                        <Tooltip
                          icon="info"
                          text={groupItem.info}
                          textColor="SLATE700"
                          backgroundColor="GOLD100"
                        />
                      )}
                    </GroupTitleContainer>
                  )}
                  <ButtonsContainer>
                    {groupItem.ctas.map((item, idx) => (
                      <FilledButton
                        key={idx}
                        color="white"
                        width="288px"
                        height="56px"
                        textSize="S18"
                        textColor="SLATE700"
                        text={item.cta}
                        href={item.link}
                        style={{ border: `1px solid ${theme.colors.SLATE300}` }}
                      />
                    ))}
                  </ButtonsContainer>
                  {groupItem.otherContent}
                </GroupContainer>
              ))}
            </GroupsWrapper>
          </SectionContainer>
        ))}
      </SectionsWrapper>
    </PageWrapper>
  );
};

export default Help;
