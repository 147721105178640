import * as Yup from "yup";

export const ChangePasswordSchema = Yup.object({
  currentPassword: Yup.string().required("Please enter your current password"),
  newPassword: Yup.string()
    .min(8, "Please enter a password of minimum 8 characters ")
    .max(64, "Please enter a password less than 64 characters")
    .required(
      "Please enter a password of minimum 8 characters and maximum of 64 characters"
    ),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("newPassword"), null],
    "Passwords must match"
  ),
});
