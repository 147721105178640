import React from "react";
import styled from "styled-components";
import {
  FilledButton,
  MEDIA_QUERIES,
  Text,
  theme,
} from "@ifgengineering/component-library";
import {
  NetworthSummaryContainer,
  DescriptionText,
  NetWorthAmount,
  NetworthSummaryProps,
} from "./NetWorthSummary.component";
import { navigate } from "gatsby";

const SampleChip = styled.div`
  padding: 4px 12px;
  background-color: ${theme.colors.SLATE900};
  width: fit-content;
  border-radius: 8px;
`;
const SampleContainer = styled.div`
  display: flex;
  padding: 32px 6.75rem 64px;
  flex-direction: column;
  justify-content: space-between;
  gap: 56px;
  @media ${MEDIA_QUERIES.PHONE} {
    padding: 20px;
  }
`;
const SampleHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const SampleHeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const SampleAmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SampleNetworthSummary: React.FC<NetworthSummaryProps> = ({
  formatCurrency,
  description,
  amount,
}) => {
  return (
    <NetworthSummaryContainer
      minHeight="424px"
      alignItems="normal"
      flexDirectionMobile="column"
    >
      <SampleContainer>
        <SampleHeaderContainer>
          <SampleHeaderTextContainer>
            <Text type="H20" color="WHITE">
              Welcome
            </Text>
            <Text type="P16" color="WHITE" fontFamily="archiaregular">
              To help you get started we have added a sample portfolio to show
              you what our track feature could look like.
            </Text>
          </SampleHeaderTextContainer>
          <FilledButton
            color="blue"
            text="Add your own data"
            width="fit-content"
            onClick={() => navigate("?view=assets")}
          />
        </SampleHeaderContainer>
        <SampleAmountContainer>
          <SampleChip>
            <Text type="U14" color="SLATE300">
              SAMPLE DATA BELOW
            </Text>
          </SampleChip>
          <NetWorthAmount removePadding={true}>
            {formatCurrency(amount)}
          </NetWorthAmount>
          <DescriptionText>{description}</DescriptionText>
        </SampleAmountContainer>
      </SampleContainer>
    </NetworthSummaryContainer>
  );
};

export default SampleNetworthSummary;
