import styled from "styled-components";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 24px;
  max-width: 600px;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    gap: 16px;
  }
`;
export const GroupTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const GroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const SectionsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;
export const PageWrapper = styled.div`
  padding-left: 196px;
  padding-top: 38px;
  height: 100%;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    padding: 24px 16px;
    padding-bottom: 180px;
  }
`;
