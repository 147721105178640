import React from "react";
import Icon, { IconType } from "@icon-park/react/es/all";
import { theme } from "../../../styles/theme";
import styled from "styled-components";
import Text from "../../../components/Text/Text.component";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const IconedTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;

const IconContainer = styled.div<{ iconBgColor: keyof typeof theme.colors }>`
  background-color: ${({ iconBgColor }) => theme.colors[iconBgColor]};
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface BenefitProps {
  icon: IconType;
  iconBgColor: keyof typeof theme.colors;
  title: string;
  description: string;
}

const BenefitComponent: React.FC<BenefitProps> = ({
  icon,
  iconBgColor,
  title,
  description,
}) => {
  return (
    <Container>
      <IconedTitleContainer>
        <IconContainer iconBgColor={iconBgColor}>
          <Icon type={icon} size={25} theme="outline" color={"red"} />
        </IconContainer>
        <Text type="H16" color="SLATE900">
          {title}
        </Text>
      </IconedTitleContainer>
      <div>
        <Text color="SLATE900" type="P16">
          {description}
        </Text>
      </div>
    </Container>
  );
};

const benefits: BenefitProps[] = [
  {
    icon: "Wallet",
    iconBgColor: "Y50",
    title: "Cheaper",
    description:
      "Most solicitors charge over £500 for a will and it could go upto £1000. Our technology allows us to bring you a complete will service for just £98.",
  },
  {
    icon: "DiamondOne",
    iconBgColor: "G50",
    title: "Better",
    description:
      "Each IFG Will is fully tax efficient, includes a full breakdown of islamic inheritance, and is signed off by a Mufti and drafted by city-trained lawyers.",
  },
  {
    icon: "DoubleRight",
    iconBgColor: "T50",
    title: "Faster",
    description:
      "Write your will in the privacy of your own home, within 20 minutes. No need of arranging appointments with solicitors. You'll have your will in within a week.",
  },
  {
    icon: "PeopleSafe",
    iconBgColor: "P50",
    title: "Expert",
    description:
      "Islamic will needs to be legally binding and adhere to the Sharia. Our in-house experts include a Mufti and 2 highly-trained lawyers.",
  },
];

const StyledBenefitsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4.5em;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 3.5em;
  }
`;

const Benefits = () => {
  return (
    <StyledBenefitsContainer>
      {benefits.map((benefit, i) => (
        <BenefitComponent key={i} {...benefit} />
      ))}
    </StyledBenefitsContainer>
  );
};

export default Benefits;
