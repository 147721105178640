import { RouteComponentProps } from "@reach/router";
// import { Link } from "gatsby";
import React from "react";
import { SEO } from "../seo/seo";

type Props = RouteComponentProps;

const VerificationError: React.FC<Props> = () => {
  return (
    <div>
      <SEO title={"Verified Email Error"} />
      <h2>There was an issue with your verification link</h2>
      {/* <p>
        Please re-generate your verification link{" "}
        <Link to="/app/profile/settings">here</Link>
      </p> */}
    </div>
  );
};

export default VerificationError;
