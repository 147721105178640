import React, { useEffect, useState, useMemo } from "react";
import { Container, SectionItemWrapper, TextWrapper } from "./styled";
import { NavigationType } from "@components/InsightNavigation/constants/types";
import Accordion from "@components/Accordion/Accordion.component";
import { Text, MEDIA_QUERIES } from "@ifgengineering/component-library";
import { useMediaQuery } from "react-responsive";
import { getInsightCategoriesAndSections } from "@components/InsightDetailPage/utlis/getInsightCategoriesAndSections";

const InsightsNavigation: React.FC<NavigationType> = ({ data, onChange }) => {
  const formatedInsights = getInsightCategoriesAndSections(data);
  const [firstCatWithSecs, setFirstCatWithSecs] = useState<
    string | undefined
  >();

  const [selected, setSelected] = useState<
    | {
        section: string;
        category: string;
      }
    | undefined
  >();

  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });

  useEffect(() => {
    if (selected) {
      onChange(selected);
    }
  }, [selected?.category, selected?.section]);

  const firstCatWithSecsFound = useMemo(
    () =>
      formatedInsights?.find((item) => item?.sections?.length > 0)?.category.id,
    [formatedInsights, firstCatWithSecs]
  );

  useEffect(() => {
    if (firstCatWithSecsFound) {
      setFirstCatWithSecs(firstCatWithSecsFound);
    }
    if (firstCatWithSecsFound) setOpenCategory(firstCatWithSecs);
  }, [firstCatWithSecsFound, firstCatWithSecs]);

  const [openCategory, setOpenCategory] = useState<string>();
  const getTextColor = (sec: string) => {
    if (selected && sec === selected.section && !isMobile) return "BLUE600";
    if (selected && sec === selected.section && isMobile) return "T100";
    if (!isMobile) return "SLATE800";
    return "WHITE";
  };

  const getTextSize = (sec: string) => {
    if (sec === selected?.section && !isMobile) return "H14";
    if (!isMobile) return "P14";
    return "P16";
  };

  const onClickCategory = (cat: string) =>
    cat === openCategory ? setOpenCategory("") : setOpenCategory(cat);

  return (
    <Container>
      {formatedInsights.map(({ category, sections }) => {
        if (!sections || !sections.length) {
          return (
            <Text
              key={category.id}
              style={{ padding: "18px 0", cursor: "pointer" }}
              type={isMobile ? "H24" : "H20"}
              color={isMobile ? "WHITE" : "BLUE900"}
              onClick={() => {
                setSelected({ category: category.id, section: "" });
              }}
            >
              {category.name}
            </Text>
          );
        } else {
          return (
            <Accordion
              key={category.id}
              isOpen={category.id === openCategory}
              onClick={() => onClickCategory(category.id)}
              title={category.name}
            >
              {sections.map(({ section }) => (
                <SectionItemWrapper key={`${category.id}-${section.id}`}>
                  <TextWrapper
                    data-testid={`${section.id}-nav-link`}
                    onClick={() =>
                      setSelected({
                        category: category.id,
                        section: section.id,
                      })
                    }
                  >
                    <Text
                      type={getTextSize(section.id)}
                      color={getTextColor(section.id)}
                    >
                      {section.name}
                    </Text>
                  </TextWrapper>
                </SectionItemWrapper>
              ))}
            </Accordion>
          );
        }
      })}
    </Container>
  );
};

export default InsightsNavigation;
