import React from "react";
import reset from "styled-reset";
import { createGlobalStyle } from "styled-components";

export const WillsResetStyle = createGlobalStyle`
  ${reset}
  html {
    font-family: "Inter", "Inter", sans-serif;
    overflow-x: hidden;
  }
`;

const TemporaryResetLayout: React.FC = ({ children }) => (
  <div>
    <WillsResetStyle />
    {children}
  </div>
);
export default TemporaryResetLayout;
