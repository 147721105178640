import React, { FC } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Text,
  TextTypeAndSize,
  MEDIA_QUERIES,
} from "@ifgengineering/component-library";
import { StyledFoter } from "./styled";

export const CompareFooter: FC = () => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const textSize: TextTypeAndSize = isMobile ? "P12" : "P16";

  return (
    <StyledFoter>
      <Text color="SLATE700" type="H20">
        Disclaimer
      </Text>
      <Text color="SLATE700" type={textSize}>
        The Compare tool is a comparison tool and should not be construed as
        financial advice. Your capital is at risk. IFG itself is not regulated
        by the FCA as it is a content platform but, where relevant, the
        providers listed are themselves regulated. The content on this website
        is for informational purposes only, it is information of a general
        nature and does not address the circumstances of any particular
        individual or entity. The value of investments can fall as well as rise
        in value and you could lose money. If you are unsure whether an
        investment is right for you, you should seek independent professional
        advice.
      </Text>
    </StyledFoter>
  );
};
