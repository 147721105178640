import React, { FC } from "react";
import { theme } from "@styles/theme";
import { CardContainer } from "./styled";

export interface CardProps {
  elevation?: number;
  background?: string;
  borderRadius?: number;
  justifyContent?: string;
}

export const Card: FC<CardProps> = ({
  elevation = 0,
  background = theme.colors.WHITE,
  borderRadius = 8,
  children,
}) => (
  <CardContainer
    elevation={elevation}
    background={background}
    borderRadius={borderRadius}
  >
    {children}
  </CardContainer>
);
