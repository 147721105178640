import React, { useState } from "react";
import { navigate } from "gatsby";
import TemporaryResetLayout from "./layout";
import styled from "styled-components";
import FilledButton from "../../../components/Button/FilledButton.component";
import LinkButton from "../../../components/Button/LinkButton.component";
import Benefits from "./benefits";
import { theme } from "../../../styles/theme";
import Text from "../../../components/Text/Text.component";
import { WILLS_SITE_URL } from "gatsby-env-variables";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../components/mediaQueries";
import headerBackgroundImageURL from "../../../assets/images/wealth-landing-page-bg.png";
import useAnalytics from "@helpers/useAnalytics";

const HeroSectionContainer = styled.section`
  background-image: url("${headerBackgroundImageURL}");
  background-size: cover;
  background-position: center;
  z-index: 3;
`;

const HeroSectionBackdropContainer = styled.div`
  height: 100%;
  width: 100%;
  backdrop-filter: blur(3px) brightness(0.3);
  padding: 6.5em 12vw;
  @media (max-width: 846px) {
    padding: 3.5em 1.5em;
    height: auto;
  }
`;

const HeroSectionContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 3;
  @media (max-width: 846px) {
    display: flex;
    gap: 2.5em;
    flex-direction: column-reverse;
    & > #play-button-container {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const HeroSectionTextContainer = styled.div`
  display: grid;
  gap: 3.75em;
  width: 70%;
  & > div > p {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 100%;
    & > div > p {
      width: 100%;
    }
  }
`;

const GetStartedButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr auto;
  align-items: center;
  gap: 2.5em;
  width: 80%;
  @media (max-width: 768px) {
    grid-template-columns: auto auto;
    gap: 1em;
    width: 100%;
  }
`;

const BenefitsSectionContainer = styled.section`
  background-color: ${theme.colors.B50};
  display: flex;
  padding: 3.5em 12vw;
  min-height: 584px;
  @media (max-width: 846px) {
    background-color: ${theme.colors.WHITE};
    padding: 3.5em 1.5em;
    height: auto;
  }
`;

const ProcessSectionContainer = styled.section`
  background-color: ${theme.colors.SLATE900};
  padding: 6em 12vw;
  min-height: 488px;
  @media (max-width: 846px) {
    padding: 3.5em 1.5em;
  }
`;

const SectionTitle = styled.h2<{
  color: keyof typeof theme.colors;
  size?: number;
}>`
  color: ${({ color }) => theme.colors[color]};
  font-family: "Livvic", "Inter", sans-serif;
  font-size: ${({ size }) => size || "1.75"}em;
`;

const ProcessSectionLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 4.5em;
  & > div {
    width: 100%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2em;
  }
`;

const ProcessListComponent = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2em;
  color: ${theme.colors.WHITE};
`;

const LegalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  cursor: pointer;
`;

const ProcessListItem = styled.li<{ selected: boolean }>`
  display: flex;
  gap: 1em;
  color: ${({ selected }) => (selected ? theme.colors.G400 : "")};
  & p:first-child {
    justify-content: ${({ selected }) => (selected ? "center" : "")};
    align-items: ${({ selected }) => (selected ? "center" : "")};
    color: ${({ selected }) => (selected ? theme.colors.G400 : "")};
    background-color: ${({ selected }) => (selected ? theme.colors.G50 : "")};
    border-radius: ${({ selected }) => (selected ? "2em" : "")};
    padding: ${({ selected }) =>
      selected ? "1em 0.6em" : "0 0.6em 1em 0.6em"};
  }
`;

const WillsLanding: React.FC = () => {
  const [canUseWill, setCanUseWill] = useState(false);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const fireAnalytics = useAnalytics("appSwitch");
  return (
    <TemporaryResetLayout>
      <HeroSectionContainer id="hero">
        <HeroSectionBackdropContainer>
          <HeroSectionContentContainer>
            <HeroSectionTextContainer>
              <div style={{ display: "grid", gap: "1.5em" }}>
                <SectionTitle color="WHITE" size={2}>
                  Islamic & fully tax efficient wills within 20 minutes
                </SectionTitle>
                <Text color="WHITE" type="P16">
                  You can choose from our £98 standard will or upgrade to our
                  £348 trust based option. The majority of your assets must be
                  in England & Wales to apply.
                </Text>
              </div>
              <LegalContainer onClick={() => setCanUseWill(!canUseWill)}>
                <input type="checkbox" checked={canUseWill} />
                <Text color="WHITE" type="P16">
                  I confirm that the majority of my assets are in England &
                  Wales
                </Text>
              </LegalContainer>
              <GetStartedButtonContainer>
                <FilledButton
                  disabled={!canUseWill}
                  color="blue"
                  text="Get Started"
                  icon="ArrowRight"
                  iconPosition="right"
                  onClick={async () => {
                    (await fireAnalytics)("Wills");
                    navigate(`${WILLS_SITE_URL}/app/wills`);
                  }}
                />
              </GetStartedButtonContainer>
            </HeroSectionTextContainer>

            <div id="play-button-container">
              <span>
                <LinkButton
                  text=""
                  color="blue"
                  icon="Play"
                  target="_blank"
                  href="https://www.youtube.com/watch?v=uSP-V5Hz_4g&ab_channel=IslamicFinanceGuru"
                  iconTheme="filled"
                  iconColor={theme.colors.G300}
                  iconSize={isMobile ? 86 : 176}
                />
              </span>
            </div>
          </HeroSectionContentContainer>
        </HeroSectionBackdropContainer>
      </HeroSectionContainer>
      <BenefitsSectionContainer id="benefits">
        <div style={{ display: "grid", gap: "1.5em" }}>
          <div>
            <SectionTitle color="SLATE900">Benefits</SectionTitle>
          </div>
          <Benefits />
        </div>
      </BenefitsSectionContainer>
      <ProcessSectionContainer id="process">
        <ProcessSectionLayout>
          <div>
            <div>
              <SectionTitle color="WHITE">
                Straight forward process to get your wills done
              </SectionTitle>
            </div>
            <br />
            <div>
              <Text color="WHITE" type="P16">
                The whole process takes ~20 minutes
              </Text>
            </div>
          </div>
          <div>
            <ProcessListComponent>
              <ProcessListItem selected={false}>
                <Text type="P16" color="WHITE">
                  1
                </Text>
                <Text type="H18" color="WHITE">
                  Answer our carefully chosen questions online
                </Text>
              </ProcessListItem>
              <ProcessListItem selected={false}>
                <Text type="P16" color="WHITE">
                  2
                </Text>
                <Text type="P16" color="WHITE">
                  We draft your Will & get official Mufti and Legal sign-off
                </Text>
              </ProcessListItem>
              <ProcessListItem selected={false}>
                <Text type="P16" color="WHITE">
                  3
                </Text>
                <Text type="P16" color="WHITE">
                  Print & sign the document and you&apos;re all done
                </Text>
              </ProcessListItem>
              <ProcessListItem selected={false}>
                <Text type="P16" color="WHITE">
                  4
                </Text>
                <Text type="P16" color="WHITE">
                  Keep your will up to date with edits as your circumstances
                  change
                </Text>
              </ProcessListItem>
            </ProcessListComponent>
          </div>
        </ProcessSectionLayout>
      </ProcessSectionContainer>
    </TemporaryResetLayout>
  );
};

export default WillsLanding;
