import { TagType } from "@components/InsightTag/constants/types";

const tagText = {
  [TagType.red]: "NEEDS IMMEDIATE ATTENTION",
  [TagType.amber]: "SOMETHING TO CONSIDER",
  [TagType.green]: "GOING GREAT",
  [TagType.jubilee_facts]: "JUBILEE FACTS",
};

export default tagText;
