import React, { useRef } from "react";
import { AccordionButton, Container, Content, StyledArrow } from "./styled";
import {
  Text,
  TextTypeAndSize,
  MEDIA_QUERIES,
  ColorType,
  theme,
} from "@ifgengineering/component-library";
import { useMediaQuery } from "react-responsive";

const Accordion: React.FC<{
  title: string;
  titleColor?: ColorType;
  titleFontSize?: TextTypeAndSize;
  isOpen?: boolean;
  onClick: () => void;
}> = ({ title, titleColor, titleFontSize, isOpen, onClick, children }) => {
  const content = useRef<HTMLInputElement>(null);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });
  const defaultTitleFontSize = isMobile ? "H24" : "H20";
  const defaultTitleColor = isMobile ? "WHITE" : "BLUE900";
  return (
    <Container>
      <AccordionButton data-testid="accordion-button" onClick={onClick}>
        <Text
          type={titleFontSize || defaultTitleFontSize}
          color={titleColor || defaultTitleColor}
        >
          {title}
        </Text>
        <StyledArrow
          type="Up"
          fill={theme.colors.SLATE500}
          size={24}
          open={isOpen}
        />
      </AccordionButton>
      <Content
        data-testid="accordion-content"
        maxHeight={isOpen ? `${content?.current?.scrollHeight}px` : "0px"}
        ref={content}
      >
        {children}
      </Content>
    </Container>
  );
};

export default Accordion;
