import styled from "styled-components";

export const InsightDetailWrapper = styled.div`
  max-width: 700px;
`;

export const InsightItemsContainer = styled.div`
  margin-top: 32px;
`;

export const InsightDescriptionContainer = styled.div``;

export const InsightMarkdownContainer = styled.div`
  margin-top: 16px;
`;

export const CTAsContainer = styled.div`
  margin-top: 32px;
`;
