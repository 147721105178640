import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  Container,
  Warning,
  Description,
  StyledButtonContainer,
} from "./NotFound.styles";
import LinkButton from "../button/LinkButton.component";
import { SEO } from "../seo";

type Props = RouteComponentProps;

const UnderConstruction: React.FC<Props> = () => {
  return (
    <Container>
      <SEO title={"Under Construction"} />
      <Warning>Coming Soon</Warning>
      <Description>
        We are currently working on this feature and will launch soon
      </Description>
      <StyledButtonContainer>
        <LinkButton to="/app/compare">Take Me Home</LinkButton>
      </StyledButtonContainer>
    </Container>
  );
};

export default UnderConstruction;
