import { Text } from "@ifgengineering/component-library";
import Icon, { IconType, Theme } from "@icon-park/react/es/all";
import { ColorType, theme } from "@styles/theme";
import React from "react";
import { StyledIconContainer, StyledTag } from "./styled";
import { TagSize } from "./types";
interface TagProps {
  backgroundColor?: ColorType;
  color?: ColorType;
  icon?: IconType;
  iconTheme?: Theme;
  iconColor?: ColorType;
  size?: TagSize;
  text: string;
}

export const Tag = ({
  backgroundColor = "SLATE100",
  color = "SLATE300",
  icon,
  iconTheme = "filled",
  iconColor,
  size = "regular",
  text,
}: TagProps): JSX.Element => (
  <StyledTag
    data-testid="tag"
    backgroundColor={backgroundColor}
    color={color}
    containIcon={!!icon}
    containText={!!text}
  >
    {icon && (
      <StyledIconContainer addMargin={!!text} size={size}>
        <Icon
          data-testid="tag-icon"
          type={icon}
          theme={iconTheme}
          fill={iconColor ? theme.colors[iconColor] : ""}
        />
      </StyledIconContainer>
    )}
    <Text
      type={size === "regular" ? "U16" : "U14"}
      color={color}
      data-testid="tag-text"
    >
      {text}
    </Text>
  </StyledTag>
);
