import React, { FC } from "react";
import { navigate } from "@reach/router";
import Icon from "@icon-park/react/es/all";
import useAnalytics from "@helpers/useAnalytics";
import { WILLS_SITE_URL, ZAKAT_SITE_URL } from "gatsby-env-variables";
import { Text, Card, theme } from "@ifgengineering/component-library";
import {
  CardContentWrapper,
  PageContentWrapper,
  IconWrapper,
  StyledTag,
  Container,
  GirdItem,
} from "./styled";
import { useSelector } from "react-redux";
import { appRecordsSelector } from "@components/Login/selectors";
import { useWillsData } from "@Wealth/hooks";

export const Tools: FC = () => {
  const fireAppSwitchAnalytics = useAnalytics("appSwitch");
  const { wills } = useWillsData();
  const userHasNoWills = wills === null || wills.status === "Not Started";
  const isZakatUser = useSelector(appRecordsSelector)?.zakat;

  const template = [
    {
      icon: "Gift",
      label: "Write Your Will",
      testId: "tools-wills",
      onClick: async () => {
        if (!userHasNoWills) {
          (await fireAppSwitchAnalytics)("Wills");
          navigate(`${WILLS_SITE_URL}/app/wills`);
        } else {
          navigate("?view=wills");
        }
      },
    },
    {
      icon: "Division",
      label: "Zakat Calculator",
      testId: "tools-zakat",
      onClick: async () => {
        if (isZakatUser) {
          (await fireAppSwitchAnalytics)("Zakat");
          navigate(`${ZAKAT_SITE_URL}/app/calculate`);
        } else {
          navigate("?view=zakat");
        }
      },
    },
    {
      icon: "CarouselVideo",
      label: "Courses",
      testId: "tools-courses",
      href: "https://www.islamicfinanceguru.com/courses",
    },
  ];

  return (
    <Container>
      <PageContentWrapper>
        {template.map((item, key) => (
          <GirdItem key={key}>
            <StyledTag
              onClick={item?.onClick && item.onClick}
              href={item?.href && item.href}
              target="_blank"
            >
              <Card elevation={1} background="WHITE" testId={item.testId}>
                <CardContentWrapper>
                  <IconWrapper>
                    <Icon
                      type={item.icon}
                      size="22px"
                      fill={theme.colors.BLUE700}
                    />
                  </IconWrapper>
                  <Text type="T24" color="SLATE800" fontFamily="archiabold">
                    {item.label}
                  </Text>
                </CardContentWrapper>
              </Card>
            </StyledTag>
          </GirdItem>
        ))}
      </PageContentWrapper>
    </Container>
  );
};

export default Tools;
