import { Field } from "formik";
import styled from "styled-components";

export const FieldContainer = styled.div`
  /* max-width: 70rem; */
  width: 100%;
  padding: 1rem 0rem;
  justify-content: center;
  align-items: center;
`;

export const FormSubHeading = styled.h3`
  font-size: var(--default-font-size);
  font-weight: 500;
  text-align: left;
  margin-bottom: 1rem;

  color: #63656c;
`;

export const StyledField = styled(Field)<{ error: boolean }>`
  border: 1px solid;
  border-color: ${(props) => (props.error ? "#ac0a40" : "#90939a")};
  box-sizing: border-box;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  /* min-width: 40rem; */
  width: 100%;
  font-size: 1rem;
`;
