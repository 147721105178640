import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import { theme } from "@styles/theme";
import styled from "styled-components";

export const VCPartnersSectionContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: ${theme.colors.WHITE};
  padding: 3em 6em;
  gap: 3em;
  overflow-x: hidden;
  align-items: center;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    padding: 3em 2em;
    gap: 1em;
  }
  @media ${MEDIA_QUERIES.PHONE} {
    padding: 3em 1em;
    display: flex;
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  max-width: 390px;
  @media ${MEDIA_QUERIES.TAB_LAND} {
    width: 100%;
  }
  & > div {
    @media ${MEDIA_QUERIES.TAB_LAND} {
      width: 60%;
    }
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 2em;
  & > img {
    height: 3vw;
    width: auto;
  }
  @media ${MEDIA_QUERIES.TAB_LAND} {
    align-self: stretch;
    & > img {
      height: 5vw;
      width: auto;
    }
  }
  @media ${MEDIA_QUERIES.PHONE} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    & > img {
      height: 10vw;
      width: auto;
    }
  }
`;
