import React, { FC } from "react";
import styled from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  overflow: hidden;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100vh;
    overflow: hidden;
  }
`;

export const Layout: FC = ({ children }) => (
  <PageWrapper>{children}</PageWrapper>
);
