import { theme } from "@styles/legacy-theme";
import styled from "styled-components";

export const Layout = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const FormWrapper = styled.div`
  width: 327px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Spacer = styled.div`
  margin-bottom: 2rem;
  width: 100%;
`;

export const Separator = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.Grey01};
  margin: 2rem 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 327px;
  flex-direction: column;
  margin: 2rem 0 2rem 0;
`;

export const Text = styled.h2<{ align?: string }>`
  width: 100%;
  font-weight: bold;
  color: ${theme.colors.BrandBlue};
  text-align: ${({ align }) => align || "left"};
  margin: 2rem 0 1rem 0;
  font-size: 1.5rem;
  text-transform: capitalize;
  line-height: 1.5rem;
`;

export const Description = styled.p`
  margin: 0 0 0.3rem 0;
  color: ${theme.colors.Font02};
`;

export const Heading = styled.p`
  color: ${theme.colors.Font02};
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
`;
