import { InsightCTAProps } from "@components/InsightCTAs/InsightCTA.component";
import InsightDetail from "@components/InsightDetail/InsightDetail.component";
import React from "react";
import { getInsightCategoriesAndSections } from "./utlis/getInsightCategoriesAndSections";
import { InsightDataInterface } from "./utlis/types";
import { Text } from "@ifgengineering/component-library";
import styled from "styled-components";
import useAnalytics from "@helpers/useAnalytics";

const Wrapper = styled.div`
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 7.5em;

  & > section > div:first-of-type > h1 {
    margin-top: 0;
  }
`;

const CategoryContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

const CategoryTexts = styled.div`
  margin-bottom: 2em;
  & > p {
    margin-top: 0.85em;
  }
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const SectionTexts = styled.div`
  margin-bottom: 2em;
  & > p {
    margin-top: 0.85em;
  }
`;

const InsightsContainer = styled.section`
  gap: 2em;
  display: flex;
  flex-direction: column;
`;

interface Props {
  insights: InsightDataInterface[];
}

const InsightDetailPageComponent: React.FC<Props> = ({ insights }) => {
  const formatedInsights = getInsightCategoriesAndSections(insights);
  const fireInsightCTAClickAnalytics = useAnalytics("insightCTAClick");

  return (
    <Wrapper>
      {formatedInsights &&
        formatedInsights.map(({ category, sections, unsectionedInsights }) => (
          <CategoryContainer id={category.id} key={category.id}>
            <CategoryTexts>
              <Text type="T32" color="BLUE900">
                {category.name}
              </Text>
              {category.description && (
                <Text type="P18" color="SLATE800">
                  {category.description}
                </Text>
              )}
            </CategoryTexts>
            {unsectionedInsights && (
              <InsightsContainer>
                {unsectionedInsights.map((insight) => {
                  const mappedCTAs: InsightCTAProps[] =
                    insight.insightCtas?.map((ctaItem) => ({
                      id: ctaItem.id,
                      buttonLink: ctaItem.link,
                      buttonText: ctaItem.linkText,
                      imageSrc: ctaItem.imageKey as string,
                      onClick: async () => {
                        (await fireInsightCTAClickAnalytics)({
                          insight_key: insight.id,
                          insight_title: insight.title,
                          insight_cta_link: ctaItem.link,
                          insight_cta_title: ctaItem.linkText,
                        });
                      },
                    }));
                  return (
                    <div
                      id={category.id + "_" + insight.id}
                      key={category.id + "_" + insight.id}
                    >
                      <InsightDetail
                        key={insight.id}
                        description={insight.description}
                        markdown={insight.detailedDescription}
                        ragStatusKey={insight.ragStatusKey}
                        ctas={mappedCTAs}
                      />
                    </div>
                  );
                })}
              </InsightsContainer>
            )}
            {sections && (
              <SectionsContainer>
                {sections.map(({ section, insights }) => (
                  <section id={section.id} key={section.id}>
                    <SectionTexts>
                      <Text type="H24" color="BLUE999">
                        {section.name}
                      </Text>
                      {section.description && (
                        <>
                          <Text type="P16" color="SLATE800">
                            {section.description}
                          </Text>
                        </>
                      )}
                    </SectionTexts>
                    {insights && insights.length && (
                      <InsightsContainer>
                        {insights.map((insight) => {
                          const mappedCTAs: InsightCTAProps[] =
                            insight.insightCtas?.map((ctaItem) => ({
                              id: ctaItem.id,
                              buttonLink: ctaItem.link,
                              buttonText: ctaItem.linkText,
                              imageSrc: ctaItem.imageKey as string,
                              onClick: async () => {
                                (await fireInsightCTAClickAnalytics)({
                                  insight_key: insight.id,
                                  insight_title: insight.title,
                                  insight_cta_link: ctaItem.link,
                                  insight_cta_title: ctaItem.linkText,
                                });
                              },
                            }));
                          return (
                            <div
                              id={category.id + "_" + insight.id}
                              key={category.id + "_" + insight.id}
                            >
                              <InsightDetail
                                description={insight.description}
                                markdown={insight.detailedDescription}
                                ragStatusKey={insight.ragStatusKey}
                                ctas={mappedCTAs}
                              />
                            </div>
                          );
                        })}
                      </InsightsContainer>
                    )}
                  </section>
                ))}
              </SectionsContainer>
            )}
          </CategoryContainer>
        ))}
    </Wrapper>
  );
};

export default InsightDetailPageComponent;
