import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import { theme } from "@styles/theme";
import styled from "styled-components";

export const StartupShowcaseSectionContainer = styled.section`
  display: flex;
  padding: 3em 6em;
  gap: 5em;
  overflow-x: hidden;
  align-items: center;
  background-color: ${theme.colors.PEBBLE100};
  @media ${MEDIA_QUERIES.TAB_PORT} {
    padding: 3em 2em;
    flex-direction: column;
    gap: 2.5em;
  }
  @media ${MEDIA_QUERIES.PHONE} {
    flex-direction: column;
    padding: 0 0;
    padding-bottom: 3em;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  @media ${MEDIA_QUERIES.PHONE} {
    padding: 0 1em;
  }
`;
