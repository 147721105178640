import {
  NavMenuItemsPosition,
  MenuTemplateItem,
} from "@ifgengineering/client-app-navbar";
import { AppNames } from "@ifgengineering/client-analytics";
import { navigate as outerNav } from "@reach/router";
import { navigate as innerNav } from "gatsby";
import { DiscoverPage } from "@Wealth/state/discover/discover.types";
import { CUR8_SITE_URL } from "gatsby-env-variables";

export const getTemplate = (
  toggleMenu: () => void,
  page: DiscoverPage,
  fireAppSwitchAnalytics: Promise<(destinationApp: AppNames) => void>
): MenuTemplateItem[] => {
  const comparePages: DiscoverPage[] = ["compare", "shortlist", "crypto"];
  const trackPages: DiscoverPage[] = [
    "track",
    "insights",
    "assets",
    "personal-finance",
  ];
  const isCompareSelected = comparePages.includes(page);
  const isTrackSelected = trackPages.includes(page);
  const isToolsNavSelected =
    isCompareSelected || isTrackSelected || page === "tools";

  return [
    {
      tab: "cur8",
      label: "Invest",
      selected: page === "cur8",
      position: NavMenuItemsPosition.MAIN,
      onClick: async () => {
        (await fireAppSwitchAnalytics)("Cur8");
        await outerNav(`${CUR8_SITE_URL}/app/deals`);
      },
    },
    {
      tab: "tools",
      label: "Tools",
      selected: isToolsNavSelected,
      position: NavMenuItemsPosition.MAIN,
      onClick: async () => {
        await innerNav(`?view=tools`);
      },
    },
    {
      tab: "help",
      label: "Help",
      selected: page === "help",
      position: NavMenuItemsPosition.SECONDARY,
      onClick: () => {
        innerNav("?view=help");
      },
    },
  ];
};
