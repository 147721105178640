import { Text } from "@ifgengineering/component-library";
import { theme } from "@styles/theme";
import React from "react";
import styled from "styled-components";

const InsightCTAContainer = styled.div`
  gap: 24px;
  min-width: 286px;
  text-align: center;
`;

const ImageContainer = styled.div`
  position: relative;
  & > img {
    width: 100%;
    height: 348px;
    object-fit: contain;
    border-radius: 8px;
  }

  h2 {
    text-align: left;
    position: absolute;
    bottom: 24px;
    padding: 0 24px;
    color: ${theme.colors.WHITE};
  }
`;

const InsightCTAButton = styled.button`
  border-radius: 4px;
  padding: 0.375em 1em;
  color: ${theme.colors.WHITE};
  background-color: ${theme.colors.BLUE999};
  width: 80%;
  margin: auto;
  margin-top: 24px;
`;

export interface InsightCTAProps {
  imageSrc: string;
  imageTitle?: string;
  buttonText: string;
  buttonLink: string;
  onClick?: VoidFunction;
}

const InsightCTA: React.FC<InsightCTAProps> = ({
  imageSrc,
  imageTitle,
  buttonText,
  buttonLink,
  onClick,
}) => {
  return (
    <InsightCTAContainer>
      {imageSrc && (
        <ImageContainer>
          <img src={imageSrc} alt={imageTitle ? imageTitle : buttonText} />
          {imageTitle && (
            <Text type="H24" color="SLATE900">
              {imageTitle}
            </Text>
          )}
        </ImageContainer>
      )}
      {buttonText && buttonLink && (
        <a href={buttonLink} target="_blank" onClick={onClick} rel="noreferrer">
          <InsightCTAButton>
            <Text type="P16" color="WHITE">
              {buttonText}
            </Text>
          </InsightCTAButton>
        </a>
      )}
    </InsightCTAContainer>
  );
};

export default InsightCTA;
