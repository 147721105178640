import styled from "styled-components";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import { theme } from "@styles/theme";

export const Container = styled.div`
  padding: 0 110px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;

  @media ${MEDIA_QUERIES.PHONE} {
    padding: 0 1rem;
  }
`;

export const HeadingContainer = styled.div`
  max-width: 375px;
  display: flex;
  align-items: center;
  gap: 2vw;
  @media ${MEDIA_QUERIES.PHONE} {
    width: 100%;
    justify-content: space-between;
  }
`;

export const DetailedInsightsButton = styled.button`
  border: solid 1.35px ${theme.colors.BLUE700};
  border-radius: 50px;
  padding: 3px 1em;
`;

export const Spacer = styled.div<{ space?: number }>`
  padding: ${({ space }) => (space ? `${space}px 0` : "30px 0")};
`;

export const BetaNoteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const InsightsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 0;
  min-width: 100%;
  max-width: calc(100vw - 500px);
  @media ${MEDIA_QUERIES.PHONE} {
    padding: 0;
  }
`;
