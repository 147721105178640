import React, { useContext, useEffect, useState } from "react";
import { CUR8_SITE_URL } from "gatsby-env-variables";
import NetWorthSummary from "@wealthComponents/NetWorthSummary/NetWorthSummary.component";
import { CurrencyContext } from "@components/CurrencyContext";
import { PortfolioAllocationBar } from "@components/PortfolioAllocationBar";
import { AssetBreakdown } from "@wealthComponents/AssetBreakdown";
import { Loading } from "@wealthComponents/loading";
import { ResponsiveContainer } from "recharts";
import CustomPieChart from "@components/PieChart/PieChart.component";
import { updateCrm } from "@helpers/crmUpdate";
import useAnalytics from "@helpers/useAnalytics";
import { Text, MEDIA_QUERIES } from "@ifgengineering/component-library";
import { navigate } from "gatsby";
import { usePortfolioData } from "@Wealth/hooks/usePortfolioData";
import { useInsightsData } from "@Wealth/hooks/useInsightsData";
import InsightScroll from "@components/Insight/InsightScroll.component";
import { InsightType } from "@components/Insight/constants/types";
import { TagType } from "@components/InsightTag/constants/types";
import {
  Container,
  InsightsContainer,
  HeadingContainer,
  Spacer,
  DetailedInsightsButton,
} from "./styled";
import { useMediaQuery } from "react-responsive";
import { getAssetValues } from "@helpers/getAssetValues";
import { getUserEmail } from "@components/Login/selectors";
import { useSelector } from "react-redux";
import { hasSavedAssetsSelector } from "@components/PortfolioData/selectors";
import { samplePortfolioData } from "./constants/sample-portfolio";

const NetWorthTracker: React.FC = () => {
  const { loading, portfolio } = usePortfolioData();
  const firePageViewAnalytics = useAnalytics("pageView");
  const fireInsightViewClickAnalytics = useAnalytics("insightViewClick");
  const { formatCurrency } = useContext(CurrencyContext);
  const { insights } = useInsightsData(6);
  const [flatInsights, setFlatInsights] = useState<InsightType[]>([]);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.TAB_PORT });
  const userEmail = useSelector(getUserEmail);
  const hasAssets = useSelector(hasSavedAssetsSelector);

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({ eventName });
    };

    firePageView("track:netWorthTracker_pageViewed");

    if (userEmail) {
      updateCrm({
        payload: {
          track_user: "true",
        },
      });
    }
  }, [userEmail]);

  useEffect(() => {
    if (insights?.length) {
      setFlatInsights(
        insights.map(({ insight }) => ({
          description: insight.description || "",
          tagType: insight.ragStatusKey as TagType,
          title: insight.title || "",
          onClick: async () => {
            (await fireInsightViewClickAnalytics)({
              insight_key: insight.id,
              insight_title: insight.title,
            });
            navigate(
              `?view=insights&insight=${
                insight.category?.id + "_" + insight.id
              }`
            );
          },
        }))
      );
    }
  }, [insights]);

  const { netWorth, totalAssetValue, debts, assetsWithTotals } = getAssetValues(
    hasAssets ? portfolio : samplePortfolioData
  );

  const pieChartAssets = assetsWithTotals.map(({ label, value }) => ({
    name: label,
    value: value,
  }));

  const pieChartAssetsWithoutDebts = pieChartAssets.filter(
    (asset) => asset.name !== "Debts"
  );

  // Only show the loading if it is loading and we don't have any data loaded
  if (loading && portfolio?.id === undefined) return <Loading />;

  const insightsList = (
    <>
      <HeadingContainer>
        <Text type="S20" color="BLUE999">
          Insights
        </Text>
        {hasAssets && (
          <DetailedInsightsButton onClick={() => navigate("?view=insights")}>
            <Text type="S14" color="BLUE700">
              View Deeper Analysis →
            </Text>
          </DetailedInsightsButton>
        )}
      </HeadingContainer>
      <Spacer space={10} />
      <InsightsContainer>
        <InsightScroll data={flatInsights} />
      </InsightsContainer>
    </>
  );

  return (
    <div>
      <NetWorthSummary
        amount={netWorth}
        description="TOTAL NET WORTH"
        formatCurrency={formatCurrency}
        sampleData={!hasAssets}
      />
      <Container>
        <Spacer />
        <PortfolioAllocationBar
          assetsValue={totalAssetValue}
          liabilitiesValue={debts}
          formatCurrency={formatCurrency}
        />
        {isMobile && insightsList}
        <Spacer />
        <AssetBreakdown
          assets={assetsWithTotals || []}
          enableEditAssets={hasAssets}
          onEdit={() => navigate("?view=assets")}
          onClickInvest={() => {
            navigate(`${CUR8_SITE_URL}/app/deals`);
          }}
          formatCurrency={formatCurrency}
        />
        <Spacer />

        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Text type="S20" color="BLUE999">
            Asset Distribution
          </Text>
          <Spacer space={10} />
          <ResponsiveContainer width="100%">
            <CustomPieChart data={pieChartAssetsWithoutDebts} />
          </ResponsiveContainer>
        </div>

        {!isMobile && insightsList}
        <Spacer />
      </Container>
    </div>
  );
};

export default NetWorthTracker;
