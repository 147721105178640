import React from "react";
import styled from "styled-components";
import Icon from "@icon-park/react/es/all";
import { theme } from "../../styles/theme";
import {
  ButtonProps,
  buttonStatesColors,
  ButtonColor,
  ButtonStates,
} from "./buttonTypes";
import Text from "../Text/Text.component";
import { InlineLoader } from "@ifgengineering/component-library";
interface FilledButtonProps extends ButtonProps {
  color: ButtonColor;
  justifyContent?: string;
  testId?: string;
  type?: string;
  isLoading?: boolean;
  customBackogrundColors?: ButtonStates;
  [x: string]: any;
}

const getColours = (
  color: ButtonColor,
  stateColors?: ButtonStates
): ButtonStates => (stateColors ? stateColors : buttonStatesColors[color]);

const getBackgroundColor = ({
  selected,
  disabled,
  color,
  customBackogrundColors,
}: FilledButtonProps) => {
  if (selected) return getColours(color, customBackogrundColors).selected;
  if (disabled) return theme.colors.SLATE200;
  return getColours(color, customBackogrundColors).default;
};

const getHoverBackogrundColor = ({
  color,
  disabled,
  customBackogrundColors,
}: FilledButtonProps) => {
  if (disabled) return "";
  return getColours(color, customBackogrundColors).hover;
};

const StyledButton = styled.button<FilledButtonProps>`
  background-color: ${(props) => getBackgroundColor(props)};
  color: ${({ disabled }) =>
    disabled ? theme.colors.SLATE400 : theme.colors.WHITE};
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: 0.375em 1em;
  height: ${({ height }) => height};
  width: ${({ width }) => width || "100%"};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => getHoverBackogrundColor(props)};
  }
  &:active {
    background-color: ${({ color, customBackogrundColors }) =>
      getColours(color, customBackogrundColors).active};
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justifyContent || "center"};
    gap: 0.5em;
    flex-direction: ${({ iconPosition }) =>
      iconPosition === "right" ? "row-reverse" : "row"};
  }
`;

const FilledButton: React.FC<FilledButtonProps> = ({
  text,
  color = "blue",
  icon,
  iconTheme,
  iconColor = "WHITE",
  iconSize,
  selected,
  disabled,
  iconPosition,
  textSize = "S14",
  textColor,
  onClick,
  href,
  target = "_blank",
  justifyContent,
  height,
  type,
  isLoading,
  testId,
  borderRadius = "4px",
  customBackogrundColors,
  ...props
}) => {
  const defaultTextColor = disabled ? "SLATE400" : "WHITE";
  const chosenTextColor = textColor || defaultTextColor;

  const button = (
    <StyledButton
      text={text}
      icon={icon}
      iconPosition={iconPosition}
      color={color}
      selected={selected}
      onClick={onClick}
      disabled={disabled}
      justifyContent={justifyContent}
      height={height}
      type={type}
      borderRadius={borderRadius}
      data-testid={testId}
      customBackogrundColors={customBackogrundColors}
      {...props}
    >
      <div>
        {icon && (
          <span>
            <Icon
              type={icon}
              size={iconSize}
              theme={iconTheme}
              fill={theme.colors[iconColor]}
            />
          </span>
        )}
        {isLoading ? (
          <InlineLoader size={20} thickness={1} />
        ) : (
          <Text color={chosenTextColor} type={textSize}>
            {text}
          </Text>
        )}
      </div>
    </StyledButton>
  );

  return href ? (
    <a href={href} target={target}>
      {button}
    </a>
  ) : (
    button
  );
};

export default FilledButton;
