import React from "react";
import Card from "@components/Card";
import InsightTag from "@components/InsightTag/InsightTag.component";
import { InsightCTAProps } from "@components/InsightCTAs/InsightCTA.component";
import InsightCTAs from "@components/InsightCTAs/InsightCTAs.component";
import { TagType } from "@components/InsightTag/constants/types";
import ReactMarkdown from "react-markdown";
import "./InsightDetail.css";
import {
  InsightDetailWrapper,
  InsightMarkdownContainer,
  CTAsContainer,
  InsightDescriptionContainer,
  InsightItemsContainer,
} from "./InsightDetail.style";
import { Text } from "@ifgengineering/component-library";
export interface InsightDetailProps {
  ragStatusKey: keyof typeof TagType;
  description?: string;
  markdown?: string;
  ctas?: InsightCTAProps[];
}

const InsightDetail: React.FC<InsightDetailProps> = ({
  ragStatusKey,
  description,
  markdown,
  ctas,
}) => {
  return (
    <InsightDetailWrapper>
      <Card elevation={2}>
        <InsightTag tagType={TagType[ragStatusKey]} />
        <InsightItemsContainer>
          {description && (
            <InsightDescriptionContainer>
              <Text type="P16" color="SLATE800">
                {description}
              </Text>
            </InsightDescriptionContainer>
          )}
          {markdown && (
            <InsightMarkdownContainer>
              <ReactMarkdown className="reactMarkdown">
                {markdown}
              </ReactMarkdown>
            </InsightMarkdownContainer>
          )}
          {ctas && (
            <CTAsContainer>
              <InsightCTAs items={ctas} />
            </CTAsContainer>
          )}
        </InsightItemsContainer>
      </Card>
    </InsightDetailWrapper>
  );
};

export default InsightDetail;
