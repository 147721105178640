export const getTrackDestination = ({
  hasAssets,
  hasTrack,
}: {
  hasAssets: boolean;
  hasTrack: boolean;
}): "landing" | "track" => {
  switch (true) {
    case !hasTrack && !hasAssets:
      return "landing";
    case hasTrack && !hasAssets:
      return "track";
    case hasTrack && hasAssets:
    default:
      return "track";
  }
};
