import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { isAuthenticatedSelector } from "../../../components/Login/selectors";
import SecurityLayer from "@ifgengineering/client-security-layer";
import PreFetchData from "../PreFetchData/PreFetchData.components";
import { navigate } from "gatsby";
import Loading from "@components/Loading";
import authSdk from "@components/Login/authSDK";

const { refreshAccessToken, userInfo } = authSdk;

export type PrivateRouteProps = {
  path: string;
  component: React.FC;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  children,
  ...rest
}) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.removeItem("intended_visit_link");
    }
  }, []);

  if (!isAuthenticated) {
    const intendedVisitLink = location.href.split(location.host)[1];
    if (!intendedVisitLink.includes("/auth/login")) {
      localStorage.setItem("intended_visit_link", intendedVisitLink);
    }
  }

  return (
    <SecurityLayer
      refreshAccessToken={refreshAccessToken}
      userInfo={userInfo}
      failureCallback={() => navigate("/auth/login/")}
      loadingComponent={<Loading />}
      userInfoSuccessCallback={(user) => user}
    >
      <PreFetchData>
        <Component {...rest}>{children}</Component>
      </PreFetchData>
    </SecurityLayer>
  );
};

export default PrivateRoute;
