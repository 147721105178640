import styled from "styled-components";
import { MEDIA_QUERIES, theme } from "@ifgengineering/component-library";

export const GirdItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardContentWrapper = styled.div`
  display: flex;
  height: 100%;
  gap: 36px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: ${theme.colors.BLUE100};
`;

export const StyledTag = styled.a`
  text-decoration: none;
  cursor: pointer;
  width: 500px;
  height: 294px;
  text-align: center;
  @media ${MEDIA_QUERIES.TAB_LAND} {
    width: 400px;
  }
`;

export const Container = styled.div`
  display: flex;
  padding: 30px;
  height: 100%;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const PageContentWrapper = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: fit-content;
  @media ${MEDIA_QUERIES.TAB_PORT} {
    grid-template-columns: 1fr;
  }
`;
