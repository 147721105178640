import React from "react";
import tagText from "./constants/tagText";
import { TagType } from "@components/InsightTag/constants/types";
import { Tag } from "@components/Tag/Tag.component";
import { insightTagColors, insightTextColors } from "./styled";

const InsightTag: React.FC<{ tagType: TagType }> = ({ tagType }) => (
  <Tag
    backgroundColor={insightTagColors[tagType]}
    color={insightTextColors[tagType]}
    text={tagText[tagType]}
    size="regular"
  />
);

export default InsightTag;
