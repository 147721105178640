import {
  fetchOrCreatePersonalFinanceAPI,
  PersonalFinanceInput,
} from "@Wealth/state/personalFinance/personalFinance.actions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import store from "../state";

export const usePersonalFinanceData = () => {
  const [loading, setLoading] = useState(true);
  const [personalFinance, setPersonalFinance] = useState<PersonalFinanceInput>(
    {}
  );
  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    const loadUser = async () => {
      const result = await dispatch(
        fetchOrCreatePersonalFinanceAPI({})
      ).unwrap();
      setPersonalFinance(result);
      setLoading(false);
    };

    loadUser();
  }, []);

  return { personalFinance, loading };
};
