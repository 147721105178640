import { Text } from "@ifgengineering/component-library";
import React, { FC, useEffect, useState } from "react";
import {
  Footer,
  HeaderText,
  InformedDecisionsContainer,
  ManageRiskContainer,
  Page,
  PortfolioManagementContainer,
  PortfolioManagementText,
  StartUsingTrackContainer,
  StartUsingTrackText,
  ZakatWithOneClickContainer,
} from "./Track.styled";
import Button from "../../../components/Button/FilledButton.component";
import meditatingDude from "@assets/images/track/zakat-meditation-landing-page.svg";
import breakdownLandingPage from "@assets/images/track/zakat-assets-breakdown-landing-page.png";
import insightsLanding from "@assets/images/track/zakat-insights-landing-page.png";
import pieChart from "@assets/images/track/zakat-pie-chart-landing-page.png";
import zakatTotalValue from "@assets/images/track/zakat-total-value-landing-page.png";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import { useMediaQuery } from "react-responsive";
import NetWorthTracker from "@wealthComponents/netWorthTracker";
import useAnalytics from "@helpers/useAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { updateAppRecord } from "@components/Login/actions";
import { updateAppRecordAPI } from "@components/Login/actions/updateAppRecordAPI";
import { AppRecordEnum } from "@components/Login/constants/types";
import { getTrackDestination } from "@wealthComponents/track/helpers/getTrackDestination";
import { appRecordsSelector } from "@components/Login/selectors";
import { hasSavedAssetsSelector } from "@components/PortfolioData/selectors";
import { usePortfolioData } from "@Wealth/hooks/usePortfolioData";

export const Track: FC = () => {
  const { loading } = usePortfolioData();
  const isTrackUser = useSelector(appRecordsSelector)?.track;
  const hasAssets = useSelector(hasSavedAssetsSelector);
  const [showLanding, setShowLanding] = useState(!isTrackUser);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const firePageViewAnalytics = useAnalytics("pageView");
  const dispatch = useDispatch();

  const goToWorthTracker = async () => {
    await dispatch(updateAppRecordAPI(AppRecordEnum.track));
    dispatch(updateAppRecord({ app: AppRecordEnum.track }));
    setShowLanding(false);
  };

  useEffect(() => {
    const destination = getTrackDestination({
      hasTrack: isTrackUser,
      hasAssets,
    });
    if (destination !== "landing" && showLanding) {
      setShowLanding(false);
    }
  }, [isTrackUser, hasAssets, loading]);

  useEffect(() => {
    dispatch(updateAppRecordAPI(AppRecordEnum.track));
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({ eventName });
    };
    if (showLanding) {
      firePageView("track:productPage_pageViewed");
    }
  }, []);

  if (!showLanding) return <NetWorthTracker />;

  return (
    <Page>
      <StartUsingTrackContainer>
        <div>
          <img
            src={meditatingDude}
            alt="A seated man surrounded by money, credit card, and documents"
          />
        </div>
        <StartUsingTrackText>
          <HeaderText isMobile={isMobile}>
            Track all your halal assets without the headache
          </HeaderText>
          <Button
            color="blue"
            text="Start using Track"
            icon="ArrowRight"
            iconPosition="right"
            textSize="S16"
            onClick={goToWorthTracker}
          />
        </StartUsingTrackText>
      </StartUsingTrackContainer>
      <PortfolioManagementContainer>
        <PortfolioManagementText>
          <Text color="SLATE800" type="T24">
            Simplify your portfolio management
          </Text>
          <Text color="SLATE700" type="P16">
            See all your assets in one place, and get a clear picture of your
            net-worth.
            <br />
            Say Maa Al-Salmah to those spreadsheets!
          </Text>
        </PortfolioManagementText>
        <img
          src={breakdownLandingPage}
          alt="Image representing the breakdown of the assets"
        />
      </PortfolioManagementContainer>
      <InformedDecisionsContainer>
        <div>
          <img src={insightsLanding} alt="Insights" />
        </div>
        <div>
          <Text color="SLATE800" type={isMobile ? "T24" : "T32"}>
            Make informed decisions
          </Text>
          <Text color="SLATE700" type="P16">
            Make better decisions on your portfolio with our range of
            personalised insights coaching you on your investment options
          </Text>
        </div>
      </InformedDecisionsContainer>
      <ManageRiskContainer>
        <div>
          <Text color="SLATE800" type={isMobile ? "T24" : "T32"}>
            Manage your risk
          </Text>
          <Text color="SLATE700" type="P16">
            Look at the distribution of your assets to understand where your
            opportunities for diversification are
          </Text>
        </div>
        <div>
          <img
            src={pieChart}
            alt="Pie chart representing the assets distribution"
          />
        </div>
      </ManageRiskContainer>
      <ZakatWithOneClickContainer>
        <div>
          <img src={zakatTotalValue} alt="Total Zakat" />
        </div>
        <div>
          <Text color="SLATE800" type={isMobile ? "T24" : "T32"}>
            Zakat with one Click
          </Text>
          <Text color="SLATE700" type="P16">
            You can use your portfolio data to to automatically calculate your
            zakat with no additional effort
          </Text>
        </div>
      </ZakatWithOneClickContainer>
      <Footer>
        <div>
          <Text color="WHITE" type="T32">
            Start managing <br />
            your wealth responsibly
          </Text>
          <Button
            color="lightBlue"
            text="Try Track Now"
            icon="ArrowRight"
            iconPosition="right"
            textSize="S16"
            onClick={goToWorthTracker}
          />
        </div>
      </Footer>
    </Page>
  );
};
