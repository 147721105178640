import { theme } from "../../../styles/theme";

const COLORS = [
  theme.colors.G75,
  theme.colors.G100,
  theme.colors.G200,
  theme.colors.G300,
  theme.colors.G400,
  theme.colors.G500,
  theme.colors.P75,
  theme.colors.P100,
  theme.colors.P200,
  theme.colors.P300,
  theme.colors.P300,
  theme.colors.P400,
  theme.colors.P500,
];

export default COLORS;
