import React, { useEffect } from "react";
import HeaderSection from "./HeaderSection/HeaderSection";
import VCPartnersSection from "./VCPartnersSection/VCPartnersSection";
import JoinCommunitySection from "./CommunitySection/CommunitySection";
import StartupShowcaseSection from "./StartupShowcaseSection/StartupShowcaseSection";
import FooterSection from "./FooterSection/FooterSection";
import useAnalytics from "@helpers/useAnalytics";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
  }
`;

const Cur8Landing: React.FC = () => {
  const firePageViewAnalytics = useAnalytics("pageView");

  const firePageView = async (eventName: string) => {
    (await firePageViewAnalytics)({ eventName });
  };

  useEffect(() => {
    firePageView("wealth:cur8ProductPage_pageViewed");
  }, []);

  return (
    <>
      <Wrapper>
        <div>
          <HeaderSection />
          <VCPartnersSection />
          <JoinCommunitySection />
          <StartupShowcaseSection />
        </div>
      </Wrapper>
      <FooterSection />
    </>
  );
};

export default Cur8Landing;
