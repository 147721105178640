import styled from "styled-components";
import { theme } from "@styles/theme";
import Icon from "@icon-park/react/es/all";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";

export const Content = styled.div<{ maxHeight: string }>`
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: hidden;
  transition: max-height 0.6s ease;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const Title = styled.p`
  font-weight: bold;
`;

export const AccordionButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  justify-content: space-between;
  gap: 10px;
`;

export const StyledArrow = styled(Icon)<{ open?: boolean }>`
  transform: ${({ open }) => !open && "rotate(180deg)"};
  fill: ${theme.colors.SLATE400};
  transition: 0.3s ease;
  @media ${MEDIA_QUERIES.PHONE} {
    fill: ${theme.colors.WHITE};
  }
`;
