import React, { createContext, useState } from "react";

export const ModalContext = createContext<{
  show: boolean;
  toggleModal: () => void;
}>({
  show: false,
  toggleModal: () => {
    // do nothing
  },
});

export const ModalProvider: React.FC = ({ children }) => {
  const [show, setShow] = useState(false);

  const toggleModal = () => setShow((prevState) => !prevState);

  return (
    <ModalContext.Provider value={{ show, toggleModal }}>
      {children}
    </ModalContext.Provider>
  );
};
