import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import styled from "styled-components";

export const AssetBreakdownContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  width: 100%;
`;

export const EditAssets = styled.div`
  align-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 20px;
  height: 100%;
  justify-content: center;
  min-width: 31%;
  min-height: 120px;

  @media ${MEDIA_QUERIES.PHONE} {
    min-width: 0;
    min-height: 0;
    min-width: 100%;
    min-height: 70px;
  }
`;
