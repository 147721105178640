import React from "react";
import { Text } from "@ifgengineering/component-library";

interface SectionGroup {
  title?: string;
  info?: string | JSX.Element;
  ctas: Array<{
    cta: string;
    link?: string;
  }>;
  otherContent?: React.ReactElement | JSX.Element;
}
interface HelpPageSection {
  sectionTitle?: string;
  groups: Array<SectionGroup>;
}
type HelpPageDataType = Array<HelpPageSection>;

export const helpPageData: HelpPageDataType = [
  {
    sectionTitle: "Send us Feedback",
    groups: [
      {
        ctas: [
          {
            cta: "Complete our Survey",
            link: "https://islamicfinanceguru.typeform.com/to/JeHHyJAc?typeform-source=islamicfinanceguru.com",
          },
        ],
      },
    ],
  },
  {
    sectionTitle: "Have a Question?",
    groups: [
      {
        title:
          "General investment queries (e.g. guidance, tips, how to get started)",
        ctas: [
          {
            cta: "Check our 101 Guides",
            link: "https://www.islamicfinanceguru.com/articles/general/halal-investing-101-guide-islamicfinanceguru",
          },
          {
            cta: "Ask our Forum",
            link: "https://forum.islamicfinanceguru.com",
          },
        ],
      },
      {
        title: "Platform related question",
        ctas: [
          {
            cta: "View our FAQs",
            link: "https://cur8.capital/faq",
          },
        ],
      },
      {
        title: "Talk to us",
        info: `We have a free community open session every Friday for 2 hours between 4pm and 6pm. The calls are maximum ten minutes long and we are on back-to-back calls. These calls are highly popular so they can sometimes be booked up to 3 months in advance. However, for people who get through the "Invest" onboarding, we will automatically call you within a week as a courtesy.`,
        ctas: [
          {
            cta: "Book a 10 min call",
            link: "https://calendly.com/ibrahim-19/ifg-drop-in?month=2022-10",
          },
        ],
      },
    ],
  },
  {
    sectionTitle: "Couldn’t find what you need?",
    groups: [
      {
        otherContent: (
          <Text type="S18" color="BLACK">
            or drop us a line at{" "}
            <span>
              <Text
                as="a"
                type="S18"
                color="BLUE600"
                href="mailto:team@cur8.capital"
                target="_blank"
              >
                team@cur8.capital
              </Text>
            </span>
          </Text>
        ),
        ctas: [
          {
            cta: "Complete Contact Form",
            link: "https://cur8.capital/contact-us",
          },
        ],
      },
    ],
  },
];
