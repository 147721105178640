import { IllustrationWithTextsProps } from "./IllustrationWithTexts.component";
import { ChipProps } from "@components/Chip/Chip.component";
import RocketImage from "@assets/images/rocket.svg";
import DiamondImage from "@assets/images/diamond.svg";
import MagnetImage from "@assets/images/magnet.svg";

import AtomicoLogo from "@assets/images/atomico.png";
import IndexVenturesLogo from "@assets/images/index-ventures.png";
import AccelLogo from "@assets/images/accel.png";
import LowercarbonCapitalLogo from "@assets/images/lowercarbon-capital.png";
import HoxtonVenturesLogo from "@assets/images/hoxton-ventures.png";
import SpeedInvestLogo from "@assets/images/speed-invest.png";
import PassionCapitalLogo from "@assets/images/passion-capital.png";
import YCombinatorLogo from "@assets/images/ycombinator.png";
import SeedcampLogo from "@assets/images/seedcamp.png";
import OctopusLogo from "@assets/images/octopus.png";

export const investmentChips: Array<ChipProps> = [
  {
    text: "Startups",
    color: "PINK800",
    backgroundColor: "PINK200",
    icon: "Rocket",
  },
  {
    text: "Real Estate",
    color: "BLUE600",
    backgroundColor: "SLATE200",
    icon: "BuildingOne",
  },
  {
    text: "Other Exciting Alternative Assets",
    color: "SUCCESS800",
    backgroundColor: "GREEN200",
    icon: "PaperMoneyTwo",
    comingSoon: true,
  },
  // {
  //   text: "Crypto",
  //   color: "GOLD800",
  //   backgroundColor: "GOLD200",
  //   icon: "Bitcoin",
  // },
];

export const illustrationWithTextsData: Array<IllustrationWithTextsProps> = [
  {
    image: RocketImage,
    title: "50",
    description: "Startups in Portfolio",
  },
  {
    image: DiamondImage,
    title: "£7m +",
    description: "Invested by our angel investors",
  },
  {
    image: MagnetImage,
    title: "2100 +",
    description: "Angels in the Network",
  },
];

export const VCLogos: { companyName: string; image: string }[] = [
  {
    companyName: "Atomico",
    image: AtomicoLogo,
  },
  {
    companyName: "Index Ventures",
    image: IndexVenturesLogo,
  },
  {
    companyName: "Accel",
    image: AccelLogo,
  },
  {
    companyName: "Lowercarbon Capital",
    image: LowercarbonCapitalLogo,
  },
  {
    companyName: "Hoxton Ventures",
    image: HoxtonVenturesLogo,
  },
  {
    companyName: "Speed Invest",
    image: SpeedInvestLogo,
  },
  {
    companyName: "Passion Capital",
    image: PassionCapitalLogo,
  },
  {
    companyName: "Y Combinator",
    image: YCombinatorLogo,
  },
  {
    companyName: "Seedcamp",
    image: SeedcampLogo,
  },
  {
    companyName: "Octopus",
    image: OctopusLogo,
  },
];
