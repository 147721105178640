import React, { useEffect, useState } from "react";
import * as queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import WillsLanding from "../wills";
import Help from "../help";
import { ChangePassword } from "../change-password";
import styled from "styled-components";
import { DiscoverPage } from "../../state/discover/discover.types";
import { setPage } from "../../state/discover/discover.actions";
import {
  NavBar,
  WealthMainPageType,
  MenuTemplateItem,
} from "@ifgengineering/client-app-navbar";
import { NavigationBar } from "@ifgengineering/component-library";
import { ModalContext, ModalProvider } from "../Modal/ModalContext";
import useAnalytics from "@helpers/useAnalytics";
import { RootState } from "../../state/rootReducer";
import { logoutAction } from "../../../components/Login/actions";
import store from "../../state";
import ZakatLanding from "../zakat";
import Track from "../track";
import AssetsPage from "../assets-page";
import { getTemplate } from "@wealthComponents/discover/helpers/template";
import { trackTabs } from "@wealthComponents/discover/helpers/trackTabs";
import PersonalFinanceQuestions from "../personal-finance-questions";
import Insights from "@wealthComponents/insights/Insights";
import Cur8Landing from "../cur8/cur8";
import { PageViewProps } from "@ifgengineering/client-analytics";
import Tools from "@components/Tools";
import Footer from "../footer";
import { DiscoverContentContainer, NavigationBarContainer } from "./styled";

const TempComingSoonPageStyled = styled.div`
  padding: 2rem;
`;

const TempComingSoonPage = (
  <TempComingSoonPageStyled>
    <h1>Coming Soon</h1>
  </TempComingSoonPageStyled>
);

const DiscoverContainer: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch<typeof store.dispatch>();
  const page = useSelector((state: RootState) => state.discover.page);
  const isCompare =
    page === "compare" || page === "shortlist" || page === "crypto";
  const isTrack = trackTabs.map((tab) => tab.id).includes(page);

  const { view } = queryString.parse(location.search);

  const onSetPage = (page: DiscoverPage) => {
    dispatch(setPage(page));
    navigate(`?view=${page}`);
  };
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const logoutCallback = () => dispatch(logoutAction());

  useEffect(() => {
    if (view && !isCompare) {
      onSetPage(view as DiscoverPage);
    } else {
      onSetPage("track");
    }
  }, [view]);

  const firePageView = async ({ eventName }: PageViewProps) => {
    (await firePageViewAnalytics)({ eventName });
  };

  const renderDiscoverComponent = () => {
    switch (page) {
      case "compare":
      case "crypto":
      case "shortlist":
      case "track":
        return <Track />;
      case "tools":
        return <Tools />;
      case "insights":
        return <Insights />;
      case "personal-finance":
        return <PersonalFinanceQuestions />;
      case "wills":
        firePageView({ eventName: "wills:productPage_pageViewed" });
        return <WillsLanding />;
      case "zakat":
        firePageView({ eventName: "zakat:productPage_pageViewed" });
        return <ZakatLanding />;
      case "beginner":
        return navigate("/app/beginner");
      case "settings":
        firePageView({ eventName: "all:settings_pageViewed" });
        return <ChangePassword />;
      case "help":
        firePageView({ eventName: "all:help_pageViewed" });
        return <Help />;
      case "assets":
        firePageView({ eventName: "track:assets_pageViewed" });
        return <AssetsPage />;
      case "cur8":
        return <Cur8Landing />;
      default:
        return TempComingSoonPage;
    }
  };

  const fireAppSwitchAnalytics = useAnalytics("appSwitch");
  const firePageViewAnalytics = useAnalytics("pageView");
  const template = getTemplate(toggleMenu, page, fireAppSwitchAnalytics);

  const navbarTitles: Partial<Record<DiscoverPage, WealthMainPageType>> = {
    compare: "Compare",
    crypto: "Compare",
    shortlist: "Compare",
    track: "Track",
    assets: "Track",
    insights: "Track",
    "personal-finance": "Track",
    cur8: "Invest",
    wills: "Wills",
    zakat: "Zakat",
    tools: "Tools",
    settings: "Settings",
    help: "Help",
  };

  const getTabs = () => {
    switch (true) {
      case isCompare:
      case isTrack:
        return trackTabs;
      case page === "tools":
      default:
        return [];
    }
  };

  const getPageName = () => {
    switch (true) {
      case isCompare:
      case isTrack:
        return "Track";
      case page === "tools":
        return "Tools";
      case page === "help":
        return "Help";
      default:
        return "Compare";
    }
  };

  return (
    <ModalProvider>
      <NavBar
        hideTopBarMobile
        hideTopBarDesktop
        template={template as MenuTemplateItem[]}
        isOpen={isMenuOpen}
        toggleMenu={toggleMenu}
        page={navbarTitles[page] as string}
        logoutCallback={logoutCallback}
        modalContext={ModalContext}
      >
        <DiscoverContentContainer>
          <NavigationBarContainer>
            <NavigationBar
              navItemsTextColor="WHITE"
              selectedNavItemsTextColor="WHITE"
              bgColor="BLACK"
              selectedNavItemsBgColor="BLUE600"
              selectedItemId={page}
              pageName={getPageName()}
              navItems={getTabs()}
              onSelectNavItem={(item) => onSetPage(item.id as DiscoverPage)}
            />
          </NavigationBarContainer>
          {renderDiscoverComponent()}
          <Footer isCompare={isCompare} isTrack={isTrack} />
        </DiscoverContentContainer>
      </NavBar>
    </ModalProvider>
  );
};

export default DiscoverContainer;
