import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import styled from "styled-components";

export const PortfolioAllocationBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media ${MEDIA_QUERIES.PHONE} {
    flex-direction: column-reverse;
  }
`;

export const ValuesContainer = styled.div`
  width: fit-content;
  display: flex;
  gap: 20px;
  margin-right: 20px;
`;

export const ValuesItem = styled.div`
  flex-grow: 1;
`;

export const Title = styled.div`
  text-transform: uppercase;
`;

export const BarContainer = styled.div`
  flex-grow: 1;
  display: flex;
  /* TODO: Replace it by the right colour when we finish with the colour migration */
  background-color: #d1ba82;
  border-radius: 8px;
  overflow: hidden;

  @media ${MEDIA_QUERIES.PHONE} {
    height: 56px;
    margin-bottom: 1rem;
  }
`;

interface AssetsBarProps {
  percentage: string;
}

export const AssetsBar = styled.div<AssetsBarProps>`
  background-color: #36b580;
  width: ${({ percentage }) => `${percentage}%`};
  border-radius: 8px 0 0 8px;
  transition: 1s;
`;

// Replace it by the Text component when we finish with the colour migration
export const TempLiabilityValue = styled.h2`
  color: #7f6629;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
`;
