import React, { FC } from "react";
import Assets from "@components/PortfolioData";
import { useUserData } from "@Wealth/hooks";
import { usePortfolioData } from "@Wealth/hooks/usePortfolioData";
import { Loading } from "@wealthComponents/loading";

const AssetsPage: FC = () => {
  useUserData();
  const { loading } = usePortfolioData();
  const destinationPage = "/app/compare?view=personal-finance";

  if (loading) return <Loading />;

  return (
    <Assets
      destinationPage={destinationPage}
      originPage="/app/compare?view=track"
      showCurrencyPage
    />
  );
};

export default AssetsPage;
