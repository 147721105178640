import React from "react";
import { Text } from "@ifgengineering/component-library";
import OxwashTeamImage from "@assets/images/oxwash-team.png";
import { StartupShowcaseSectionContainer, TextContainer } from "./styled";

const StartupShowcaseSection = () => (
  <StartupShowcaseSectionContainer>
    <img src={OxwashTeamImage} alt="oxwash team" />
    <TextContainer>
      <Text type="T32" fontFamily="archiasemibold" color="SLATE900">
        {"Back today's startups solving tomorrow's problems."}
      </Text>
      <Text type="S18" color="SLATE600">
        Invest into founders like ex-NASA scientist Kyle Grant, whose startup
        OxWash is using space-age technology to wash laundry with zero-carbon
        emissions.
      </Text>
      <Text type="S18" color="SLATE600">
        Cur8 Capital invested alongside Twitter co-founder BizStone into OxWash
        in March 2020.
      </Text>
    </TextContainer>
  </StartupShowcaseSectionContainer>
);

export default StartupShowcaseSection;
