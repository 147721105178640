import styled from "styled-components";
import React, { ReactText } from "react";
import { Text } from "@ifgengineering/component-library";

type Props = {
  name: string;
  entry?: {
    strokeDasharray?: ReactText;
    percent?: number;
    value?: string;
  };
  color?: string;
  onMouseEnter: () => void;
  onMouseOut: () => void;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0.5rem;
  min-width: fit-content;
`;

const ColorTag = styled.div`
  background-color: ${({ color }) => color};
  width: 0.625rem;
  min-width: 0.625rem;
  height: 0.625rem;
  min-height: 0.625rem;
  border-radius: 50%;
  margin: 0.125rem 0.5rem;
`;

const Label = styled(Text)`
  padding-right: 0.25em;
`;

const LegendComponent: (props: Props) => JSX.Element = ({
  color,
  name,
  entry,
  onMouseEnter,
  onMouseOut,
}: Props) => (
  <Wrapper
    data-testid="legend-component"
    onMouseOver={onMouseEnter}
    onMouseLeave={onMouseOut}
  >
    <ColorTag color={color} />
    <Label color="BLUE900" type="P14">
      {name}
    </Label>
    <Text color="BLUE900" type="H14">
      {entry?.percent ? (entry.percent * 100).toFixed(2) : 0}%
    </Text>
  </Wrapper>
);

export default LegendComponent;
