import { UserInfoInterface } from "@ifgengineering/hip-app-domain";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import store from "../state";
import { fetchUserAPI } from "../state/user/user.actions";

export const useUserData = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserInfoInterface | null>(null);
  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    const loadUser = async () => {
      const user = await dispatch(fetchUserAPI()).unwrap();
      setUser(user);
      setLoading(false);
    };

    loadUser();
  }, []);

  return { user, loading };
};
