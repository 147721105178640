import styled from "styled-components";
import { theme } from "@styles/theme";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import { Text } from "@ifgengineering/component-library";

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2em;
  overflow-x: auto;
  padding-bottom: 1em;
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.BLUE999};
    -webkit-border-radius: 1ex;
  }
  ::-webkit-scrollbar {
    height: 8px;
    background: #fff;
    -webkit-border-radius: 1ex;
  }
`;

export const Container = styled.div`
  width: 496px;
  min-width: 496px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${MEDIA_QUERIES.PHONE} {
    min-width: 310px;
    width: 100%;
  }
`;

export const Button = styled.button`
  margin-top: 1.5em;
  width: 115px;
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 16px;
  background-color: ${theme.colors.BLUE100};
  color: ${theme.colors.BLUE600};
  text-align: center;
`;

export const Heading = styled.button`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;
`;

export const SpaceBetween = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
`;

export const Description = styled(Text)`
  text-align: left;
`;
