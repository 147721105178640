import { FormikForm } from "@legacyComponents/Form/input/styled/Form";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import { Field, Formik } from "formik";
import React, { FC, useContext, useEffect, useState } from "react";
import { navigate } from "@reach/router";
import LockIcon from "@assets/icons/lock.svg";
import {
  ButtonsContainer,
  Description,
  FormWrapper,
  Layout,
  Heading,
  Text,
} from "./styled";
import MoneyInput from "@legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import { CurrencyContext } from "@components/CurrencyContext";
import Input from "@legacyComponents/Form/input/Input/Input.component";
import { useWillsData } from "@Wealth/hooks";
import { usePersonalFinanceData } from "@Wealth/hooks/usePersonalFinanceData";
import { Loading } from "@wealthComponents/loading";
import {
  PersonalFinanceInput,
  updatePersonalFinanceAPI,
} from "@Wealth/state/personalFinance/personalFinance.actions";
import { store } from "../../state";
import { useDispatch } from "react-redux";
import {
  CheckboxComponentProps,
  CheckboxInput,
  CheckboxInputDirection,
  CheckboxInputGroup,
  RadioComponentProps,
  RadioInput,
  RadioInputDirection,
  RadioInputGroup,
} from "@ifgengineering/component-library";

const defaultFormValues: PersonalFinanceInput = {
  hasIslamicWill: false,
  investmentFrequency: "",
  monthlyExpenditure: 0,
  monthlyIncome: 0,
  savingGoals: [],
};

const PersonalFinanceQuestions: FC = () => {
  const { currencySymbol } = useContext(CurrencyContext);
  const { wills } = useWillsData();
  const { personalFinance, loading } = usePersonalFinanceData();
  const dispatch = useDispatch<typeof store.dispatch>();
  const [showOtherGoal, setShowOtherGoal] = useState<boolean>(false);
  const [otherGoal, setOtherGoal] = useState<string>("");

  const handleSubmit = async ({
    hasIslamicWill,
    investmentFrequency,
    monthlyExpenditure,
    monthlyIncome,
    savingGoals = [],
  }: PersonalFinanceInput) => {
    const savingGoalsWithOther = otherGoal
      ? [otherGoal, ...savingGoals]
      : savingGoals;

    const result = await dispatch(
      updatePersonalFinanceAPI({
        hasIslamicWill,
        investmentFrequency,
        monthlyExpenditure,
        monthlyIncome,
        savingGoals: savingGoalsWithOther,
      })
    ).unwrap();

    if (result) {
      navigate("/app/compare?view=track");
    }
  };

  const findSavingGoal = (goal: string, savingGoals: string[]): boolean =>
    savingGoals.some((sg) => sg === goal);

  const toggleSavingGoals = (goal: string, savingGoals: string[]): string[] => {
    const itContainsGoal = findSavingGoal(goal, savingGoals);

    return itContainsGoal
      ? savingGoals.filter((sg) => sg !== goal)
      : [goal, ...savingGoals];
  };

  const getOtherGoal = (savingGoals: string[]): string => {
    const fixedGoals = ["dont-save", "kids", "house", "retirement"];
    const [otherGoal] = savingGoals.filter((n) => !fixedGoals.includes(n));

    return otherGoal || "";
  };

  const isThereAnotherGoal = (savingGoals: string[]): boolean => {
    const otherGoal = getOtherGoal(savingGoals);

    return Boolean(otherGoal);
  };

  useEffect(() => {
    if (isThereAnotherGoal(personalFinance.savingGoals || [])) {
      const otherGoal = getOtherGoal(personalFinance.savingGoals || []);

      setShowOtherGoal(true);
      setOtherGoal(otherGoal);
    }
  }, [personalFinance.savingGoals]);

  if (loading) return <Loading />;

  const hasIFGWills = Boolean(wills) && wills?.status !== "Not Started";

  return (
    <Layout>
      <FormWrapper>
        <Text align="left">Personal Finance Questions</Text>
        <Description>
          Completing these questions will enable us to tailor a range of
          insights to your circumstances
        </Description>
        <Formik
          enableReinitialize
          initialValues={{
            ...defaultFormValues,
            ...personalFinance,
            ...(hasIFGWills ? { hasIslamicWill: hasIFGWills } : {}),
          }}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ values, setFieldValue, submitForm }) => (
            <FormikForm>
              <MoneyInput
                heading="What is  your monthly income (after tax)?"
                value={values.monthlyIncome?.toString() || ""}
                onChange={(value) =>
                  setFieldValue("monthlyIncome", Number(value))
                }
                currency={currencySymbol}
              />
              <MoneyInput
                heading="What is your monthly expenditure"
                value={values.monthlyExpenditure?.toString() || ""}
                onChange={(value) =>
                  setFieldValue("monthlyExpenditure", Number(value))
                }
                currency={currencySymbol}
              />
              <Heading>How frequently do you invest?</Heading>
              <RadioInputGroup direction={RadioInputDirection.VERTICAL}>
                <Field
                  label="Never"
                  value="never"
                  checked={values.investmentFrequency === "never"}
                  onChange={(value: string) =>
                    setFieldValue("investmentFrequency", value)
                  }
                  component={(props: RadioComponentProps) => (
                    <RadioInput {...props} />
                  )}
                />
                <Field
                  label="Regularly (e.g. Monthly or quarterly)"
                  value="monthly"
                  checked={values.investmentFrequency === "monthly"}
                  onChange={(value: string) =>
                    setFieldValue("investmentFrequency", value)
                  }
                  component={(props: RadioComponentProps) => (
                    <RadioInput {...props} />
                  )}
                />
                <Field
                  label="Occasionally (e.g. Yearly)"
                  value="yearly"
                  checked={values.investmentFrequency === "yearly"}
                  onChange={(value: string) =>
                    setFieldValue("investmentFrequency", value)
                  }
                  component={(props: RadioComponentProps) => (
                    <RadioInput {...props} />
                  )}
                />
              </RadioInputGroup>
              <Heading>What are you saving for?</Heading>
              <CheckboxInputGroup direction={CheckboxInputDirection.VERTICAL}>
                <Field
                  key="dont-save"
                  value="dont-save"
                  label="I don't save"
                  checked={findSavingGoal(
                    "dont-save",
                    values.savingGoals || []
                  )}
                  onChange={(value: string) => {
                    setFieldValue(
                      "savingGoals",
                      toggleSavingGoals(value, values.savingGoals || [])
                    );
                  }}
                  component={(props: CheckboxComponentProps) => (
                    <CheckboxInput {...props} />
                  )}
                />
                <Field
                  key="kids"
                  value="kids"
                  label="Kids"
                  checked={findSavingGoal("kids", values.savingGoals || [])}
                  onChange={(value: string) => {
                    setFieldValue(
                      "savingGoals",
                      toggleSavingGoals(value, values.savingGoals || [])
                    );
                  }}
                  component={(props: CheckboxComponentProps) => (
                    <CheckboxInput {...props} />
                  )}
                />
                <Field
                  key="house"
                  value="house"
                  label="House"
                  checked={findSavingGoal("house", values.savingGoals || [])}
                  onChange={(value: string) => {
                    setFieldValue(
                      "savingGoals",
                      toggleSavingGoals(value, values.savingGoals || [])
                    );
                  }}
                  component={(props: CheckboxComponentProps) => (
                    <CheckboxInput {...props} />
                  )}
                />
                <Field
                  key="retirement"
                  value="retirement"
                  label="Retirement"
                  checked={findSavingGoal(
                    "retirement",
                    values.savingGoals || []
                  )}
                  onChange={(value: string) => {
                    setFieldValue(
                      "savingGoals",
                      toggleSavingGoals(value, values.savingGoals || [])
                    );
                  }}
                  component={(props: CheckboxComponentProps) => (
                    <CheckboxInput {...props} />
                  )}
                />
                <Field
                  key="other"
                  value="other"
                  label="Other"
                  checked={showOtherGoal}
                  onChange={() => {
                    if (showOtherGoal) {
                      setFieldValue(
                        "savingGoals",
                        toggleSavingGoals(otherGoal, values.savingGoals || [])
                      );
                      setOtherGoal("");
                    }
                    setShowOtherGoal(!showOtherGoal);
                  }}
                  component={(props: CheckboxComponentProps) => (
                    <CheckboxInput {...props} />
                  )}
                />
                {showOtherGoal && (
                  <Input
                    placeholder="Enter"
                    value={otherGoal}
                    onChange={(e) => setOtherGoal(e.target.value)}
                  />
                )}
              </CheckboxInputGroup>
              {!hasIFGWills && (
                <>
                  <Heading>Do you have an Islamic will?</Heading>
                  <RadioInputGroup direction={RadioInputDirection.VERTICAL}>
                    <Field
                      label="Yes"
                      value={true}
                      checked={values.hasIslamicWill === true}
                      onChange={(value: boolean) =>
                        setFieldValue("hasIslamicWill", value)
                      }
                      component={(props: RadioComponentProps) => (
                        <RadioInput {...props} />
                      )}
                    />
                    <Field
                      label="No"
                      value={false}
                      checked={values.hasIslamicWill === false}
                      onChange={(value: boolean) => {
                        setFieldValue("hasIslamicWill", value);
                      }}
                      component={(props: RadioComponentProps) => (
                        <RadioInput {...props} />
                      )}
                    />
                  </RadioInputGroup>
                </>
              )}
              <ButtonsContainer>
                <FormButton
                  type="submit"
                  text="Save & Continue"
                  onClick={submitForm}
                  leftArrowOnClick={() => navigate(-1)}
                  leftArrowLabel="Back"
                  disclaimerIcon={<LockIcon />}
                  disclaimerText={
                    <div>
                      We take your{" "}
                      <a
                        href="https://www.islamicfinanceguru.com/security-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        privacy seriously
                      </a>
                      . Our institutional-grade security ensures your data is
                      confidential.
                    </div>
                  }
                />
              </ButtonsContainer>
            </FormikForm>
          )}
        </Formik>
      </FormWrapper>
    </Layout>
  );
};

export default PersonalFinanceQuestions;
