import React from "react";
import styled from "styled-components";
import {
  PartnerCharityChip,
  PartnerCharityChipProps,
} from "./PartnerCharityChip.component";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 1em;
  & > div {
    width: auto;
  }
  @media (max-width: 846px) {
    flex-wrap: wrap;
    gap: 0.9em 0.8em;
  }
  @media (max-width: 345px) {
    flex-wrap: wrap;
    gap: 0.75em 0.5em;
  }
`;

interface PartnerCharityChipListProps {
  charities: PartnerCharityChipProps[];
}

const PartnerCharityChipList: React.FC<PartnerCharityChipListProps> = ({
  charities,
}) => {
  return (
    <Container>
      {charities
        .sort((a, b) => a.name.length - b.name.length)
        .map((charity, idx) => (
          <PartnerCharityChip key={idx} {...charity} />
        ))}
    </Container>
  );
};

export default PartnerCharityChipList;
