import Card from "@components/Card";
import {
  Text,
  TextType,
  MEDIA_QUERIES,
} from "@ifgengineering/component-library";
import React, { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { CardExpander, Spacing } from "./AssetCard.styled";
interface AssetCardProps {
  label: string;
  value: number;
  formatCurrency: (x: number) => string;
}

export const AssetCard: FC<AssetCardProps> = ({
  label,
  value,
  formatCurrency,
}) => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const labelTextType: keyof typeof TextType = isMobile ? "S16" : "S18";
  const valueTextType: keyof typeof TextType = isMobile ? "H18" : "H32";

  return (
    <CardExpander>
      <Card>
        <Spacing>
          <Text type={labelTextType} color="SLATE500">
            {label}
          </Text>
          <Text type={valueTextType} color="BLUE999">
            {formatCurrency(value)}
          </Text>
        </Spacing>
      </Card>
    </CardExpander>
  );
};
