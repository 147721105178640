import React from "react";
import styled from "styled-components";
import Icon from "@icon-park/react/es/all";
import { theme } from "../../styles/theme";
import { ButtonProps, buttonStatesColors, ButtonColor } from "./buttonTypes";
import { Text } from "@ifgengineering/component-library";

interface LinkProps extends Omit<ButtonProps, "onClick"> {
  color: ButtonColor;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
}

const StyledLink = styled.a<LinkProps>`
  width: fit-content;
  cursor: pointer;
  outline: 0;
  background-color: ${({ selected, color }) =>
    selected ? buttonStatesColors[color].selected : ""};
  color: ${({ disabled, selected, color }) => {
    if (disabled) return theme.colors.SLATE400;
    if (selected) return theme.colors.WHITE;
    return buttonStatesColors[color].default;
  }};
  padding: ${({ selected }) => (selected ? "0.5em 1em" : "")};
  &:hover {
    color: ${({ color, disabled }) =>
      disabled ? "" : buttonStatesColors[color].hover};
  }
  &:active {
    border-style: solid;
    border-width: 1px;
    border-color: ${({ color }) => buttonStatesColors[color].active};
    color: ${({ color }) => buttonStatesColors[color].active};
    outline: 0;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 0.5em;
    flex-direction: ${({ iconPosition }) =>
      iconPosition === "right" ? "row-reverse" : "row"};
  }
`;

const LinkButton: React.FC<LinkProps> = ({
  text,
  textColor = "BLUE600",
  textSize = "S16",
  color = "blue",
  iconTheme = "outline",
  iconSize = 25,
  iconColor,
  icon,
  ...rest
}) => (
  <StyledLink text={text} icon={icon} color={color} {...rest}>
    <span>
      {icon && (
        <span>
          <Icon
            type={icon}
            size={iconSize}
            theme={iconTheme}
            fill={iconColor}
          />
        </span>
      )}
      <Text type={textSize} color={textColor}>
        {text}
      </Text>
    </span>
  </StyledLink>
);

export default LinkButton;
