export const samplePortfolioData = {
  id: 123456789,
  estimatedEstateValue: null,
  cash: {
    id: 149,
    cashInHand: 3200,
    cashISA: 800,
    totalCashValue: 4000,
  },
  bankAccounts: {
    id: 84,
    totalValue: 19800,
    bankAccountList: [
      {
        id: 201,
        nameOnCard: "Mr IFG",
        sortCode: null,
        accountNumber: "12345678",
        bankAccountName: "Standard Chartered",
        cashInAccount: 19800,
      },
    ],
  },
  commodities: {
    id: 45,
    totalValue: 8950,
    commoditiesList: [
      {
        id: 256,
        name: null,
        commodityType: "GOLD",
        description: "Gold Pen",
        monetaryValue: 6750,
        carat: null,
        weight: null,
      },
      {
        id: 257,
        name: null,
        commodityType: "SILVER",
        description: "Wedding Ring",
        monetaryValue: 2200,
        carat: null,
        weight: null,
      },
    ],
  },
  cryptoAccounts: null,
  stockAccounts: null,
  debts: {
    id: 75,
    totalValue: 90000,
    debt: [
      {
        id: 269,
        type: "MORTGAGE",
        value: 90000,
        description: "my property mortgage",
      },
    ],
  },
  childTrustFunds: {
    id: 33,
    totalValue: 12000,
    childTrustFundList: [
      {
        id: 39,
        totalValue: 12000,
        nameOfChild: "Adam",
        description: "my child's education",
      },
    ],
  },
  funds: null,
  stocks: {
    id: 34,
    totalValue: 9000,
    shareList: [
      {
        id: 65,
        currency: "gbp",
        totalValue: 4500,
        shareName: "Some Random Company",
        numShares: 11,
        market: "QQ@W",
        nameTicker: "DIGILIT_TT",
      },
      {
        id: 56,
        currency: "gbp",
        totalValue: 4500,
        shareName: "Apple Inc",
        numShares: 0,
        market: "101",
        nameTicker: "AAPL",
      },
    ],
  },
  crypto: {
    id: 43,
    totalValue: 74000,
    cryptoList: [
      {
        id: 50,
        coinName: "$BTC",
        totalValue: 74000,
        coinID: null,
        numCoins: null,
      },
    ],
  },
  startups: {
    id: 27,
    totalValue: 11500,
    startupInvestments: [
      {
        id: 29,
        companyName: "Company X",
        value: 11500,
        numShares: null,
        companyID: null,
        description: null,
      },
    ],
  },
  pensions: {
    id: 37,
    totalValue: 150000,
    pensionList: [
      {
        id: 57,
        description: "workplace pension",
        employerName: null,
        pensionID: null,
        pensionType: null,
        totalValue: 150000,
        pensionProviderName: null,
        pensionName: null,
      },
    ],
  },
  properties: {
    id: 34,
    totalValue: 450000,
    propertyList: [
      {
        id: 43,
        name: null,
        description: "Property in Chelsea",
        postcode: null,
        bedroom: null,
        value: 450000,
      },
    ],
  },
  businessAssets: {
    id: 25,
    totalValue: 1200000,
    businessList: [
      {
        id: 31,
        businessName: "Company A Limited",
        totalValue: 1200000,
      },
    ],
  },
  owedAssets: {
    id: 23,
    totalValue: 10000,
    owedAssetList: [
      {
        id: 25,
        nameOfItem: null,
        nameOfEntityLentTo: null,
        description: "money lent to a friend to keep their company afloat",
        value: 10000,
      },
    ],
  },
  agricultureAssets: {
    id: 25,
    totalValue: 40000,
    agricultureList: [
      {
        id: 28,
        name: null,
        description: "10 hectare farmland in Nigeria",
        value: 40000,
      },
    ],
  },
  alternativeInvestments: {
    id: 34,
    totalValue: 12000,
    alternativeAssetList: [
      {
        id: 36,
        name: "Internet SME",
        description: "Money lent to an SME business",
        value: 12000,
      },
    ],
  },
};
