import React, { HTMLAttributeAnchorTarget } from "react";
import styled from "styled-components";
import { switchProp } from "styled-tools";
import TextType from "./types/textTypes";
import tags from "./constants/tags";
import { theme } from "../../styles/theme";
import "@assets/fonts/font.css";

type FontFamilyType =
  | "archiaregular"
  | "archialight"
  | "archiathin"
  | "archiamedium"
  | "archiasemibold"
  | "archiabold"
  | "Inter"
  | "sans-serif"
  | "Inter"
  | "monospace"
  | "Arial"
  | "Roboto"
  | "Roboto Mono"
  | "Lato"
  | "Livvic"
  | "inherit";

export type TextTypeAndSize = keyof typeof TextType;
export type TextProps = {
  type: TextTypeAndSize;
  color: keyof typeof theme.colors;
  as?: keyof HTMLElementTagNameMap;
  testId?: string;
  href?: string;
  align?: string;
  target?: HTMLAttributeAnchorTarget;
  fontFamily?: FontFamilyType;
  [x: string]: unknown;
};

const StyledText = styled.p<TextProps>`
  font-family: "Inter", sans-serif;
  font-size: ${switchProp("type", {
    [TextType.T48]: "48px",
    [TextType.T40]: "40px",
    [TextType.T32]: "32px",
    [TextType.T24]: "24px",
    [TextType.T18]: "18px",
    [TextType.H32]: "32px",
    [TextType.H24]: "24px",
    [TextType.H20]: "20px",
    [TextType.H18]: "18px",
    [TextType.H16]: "16px",
    [TextType.H14]: "14px",
    [TextType.H12M]: "12px",
    [TextType.H12]: "12px",
    [TextType.H10]: "10px",
    [TextType.S20]: "20px",
    [TextType.S18]: "18px",
    [TextType.S16]: "16px",
    [TextType.S14]: "14px",
    [TextType.P18]: "18px",
    [TextType.P16]: "16px",
    [TextType.P14]: "14px",
    [TextType.P12]: "12px",
    [TextType.CM14]: "14px",
    [TextType.CD12]: "12px",
    [TextType.U16]: "16px",
    [TextType.U16R]: "16px",
    [TextType.U14]: "14px",
    [TextType.U14R]: "14px",
  })};
  line-height: ${switchProp("type", {
    [TextType.T48]: "64px",
    [TextType.T40]: "64px",
    [TextType.T32]: "38px",
    [TextType.T24]: "32px",
    [TextType.T18]: "24px",
    [TextType.H32]: "40px",
    [TextType.H24]: "32px",
    [TextType.H20]: "24px",
    [TextType.H18]: "24px",
    [TextType.H16]: "24px",
    [TextType.H14]: "24px",
    [TextType.H12M]: "16px",
    [TextType.H12]: "16px",
    [TextType.H10]: "16px",
    [TextType.S20]: "24px",
    [TextType.S18]: "24px",
    [TextType.S16]: "24px",
    [TextType.S14]: "24px",
    [TextType.P18]: "32px",
    [TextType.P16]: "24px",
    [TextType.P14]: "20px",
    [TextType.P12]: "16px",
    [TextType.CM14]: "16px",
    [TextType.CD12]: "16px",
    [TextType.U16]: "24px",
    [TextType.U16R]: "24px",
    [TextType.U14]: "24px",
    [TextType.U14R]: "24px",
  })};
  font-weight: ${switchProp("type", {
    [TextType.T48]: 500,
    [TextType.T40]: 600,
    [TextType.T32]: 500,
    [TextType.T24]: 600,
    [TextType.T18]: 600,
    [TextType.H32]: 600,
    [TextType.H24]: 600,
    [TextType.H20]: 600,
    [TextType.H18]: 600,
    [TextType.H16]: 700,
    [TextType.H14]: 600,
    [TextType.H12M]: 500,
    [TextType.H12]: 600,
    [TextType.H10]: 500,
    [TextType.S20]: 500,
    [TextType.S18]: 500,
    [TextType.S16]: 500,
    [TextType.S14]: 500,
    [TextType.P18]: 400,
    [TextType.P16]: 400,
    [TextType.P14]: 400,
    [TextType.P12]: 400,
    [TextType.CM14]: 400,
    [TextType.CD12]: 400,
    [TextType.U16]: 600,
    [TextType.U16R]: 400,
    [TextType.U14]: 600,
    [TextType.U14R]: 400,
  })};
  letter-spacing: ${switchProp("type", {
    [TextType.U16]: "1px",
  })};
  text-transform: ${switchProp("type", {
    [TextType.U16]: "uppercase",
    [TextType.U16R]: "uppercase",
    [TextType.U14]: "uppercase",
    [TextType.U14R]: "uppercase",
  })};
  color: ${({ color }) => theme.colors[color]};
  text-align: ${({ align }) => align};
  font-family: ${({ fontFamily }) => fontFamily};
  -webkit-font-smoothing: antialiased;
`;

export const Text: React.FC<TextProps> = ({
  type,
  color,
  testId,
  ...props
}) => (
  <StyledText
    data-testid={testId || "text-component"}
    type={TextType[type]}
    color={color}
    as={tags[TextType[type]]}
    {...props}
  />
);

export default Text;
