import React, { FC } from "react";
import { TrackFooter } from "./TrackFooter";
import { CompareFooter } from "./CompareFooter";

interface FooterProps {
  isTrack: boolean;
  isCompare: boolean;
}
const Footer: FC<FooterProps> = ({ isTrack, isCompare }) => {
  switch (true) {
    case isTrack:
      return <TrackFooter />;
    case isCompare:
      return <CompareFooter />;
    default:
      return null;
  }
};

export default Footer;
