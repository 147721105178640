import { Close } from "@icon-park/react";
import React from "react";
import styled, { keyframes } from "styled-components";
import { theme } from "../../../styles/theme";

type Props = {
  open: boolean;
  onClose: () => void;
  closeOnOutsideClick?: boolean;
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Overlay = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  animation: ${fadeIn} 0.2s;
  z-index: 99999;
  display: ${({ show }) => (show ? "flex" : "none")};
  justify-content: flex-end;
`;

const StyledModal = styled.div`
  overflow: scroll;
  position: relative;
`;

const StyledCross = styled(Close)`
  position: absolute;
  cursor: pointer;
  top: 0.75rem;
  right: 0.75rem;
`;

const ModalContent = styled.div``;

/**
 * This Modal is was specially created for the mobile filter experience.
 * So it might not work well in other parts of the app.
 * You may reference its usage with React context `ModalContext`
 */

const Modal: React.FC<Props> = ({
  open,
  onClose,
  children,
  closeOnOutsideClick,
}) => {
  return (
    <Overlay onClick={() => closeOnOutsideClick && onClose()} show={open}>
      <StyledModal
        data-testid="modal-component"
        onClick={(e) => e.stopPropagation()}
      >
        <StyledCross
          fill={theme.colors.SLATE700}
          strokeWidth={5}
          data-testid="close-icon"
          onClick={onClose}
        />
        <ModalContent>{children}</ModalContent>
      </StyledModal>
    </Overlay>
  );
};

export default Modal;
