import {
  CatsAndSecsInterface,
  InsightDataInterface,
  TransformedInsightInterface,
  TransformedSectionInterface,
} from "./types";

export const getInsightCategoriesAndSections = (
  insights: InsightDataInterface[]
): TransformedInsightInterface[] => {
  // cats => categories
  // secs => sections
  // catsAndSecs => categories and sections
  const catsAndSecs: CatsAndSecsInterface = new Map();

  for (let i = 0; i < insights.length; i++) {
    const {
      insight: { category, section, ...mainInsightData },
    } = insights[i];

    // check if category doesn't exist
    const isCatExist = catsAndSecs.has(category.id);
    if (!isCatExist) {
      // check if the insight doesn't belong to any section
      if (!section) {
        catsAndSecs.set(category.id, {
          category,
          sections: new Map(),
          unsectionedInsights: [mainInsightData],
        });
      } else {
        catsAndSecs.set(category.id, {
          category,
          sections: new Map<string, TransformedSectionInterface>().set(
            section.id,
            {
              section,
              insights: [mainInsightData],
            }
          ),
          unsectionedInsights: [],
        });
      }
    }

    // check if category exists
    if (isCatExist) {
      // check if section exists in that category
      const isSecExist = catsAndSecs
        .get(category.id)
        ?.sections.has(section?.id || "");

      if (!isSecExist && section?.id) {
        catsAndSecs.get(category.id)?.sections.set(section.id, {
          section,
          insights: [mainInsightData],
        });
      }

      if (isSecExist) {
        const sec = catsAndSecs
          .get(category.id)
          ?.sections.get(section?.id || "");
        sec?.insights.push(insights[i].insight);
      }

      // check if current item does not belong to a section
      if (!section) {
        catsAndSecs
          .get(category.id)
          ?.unsectionedInsights?.push(mainInsightData);
      }
    }
  }

  // return an array of categories with their sections
  // where each section contains an array of insights
  return Array.from(catsAndSecs.values()).map((item) => {
    const { category, sections, unsectionedInsights } = item;

    return {
      category,
      sections: Array.from(sections.values()),
      unsectionedInsights,
    };
  });
};
